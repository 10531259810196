import React from 'react'
import { Link } from 'react-router-dom';
import {BsArrowLeftCircleFill} from "react-icons/bs";

export default function BackPageBtn({pageName,path}) {
  return (
    <Link className='flex items-center mt-5 mr-auto hover:text-sky-400 w-fit' to={path}>
        العودة لصفحة {pageName}
        <BsArrowLeftCircleFill size={"20px"} className='mr-1'/>
    </Link>
  )
}
