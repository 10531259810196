import React from "react";

export default function CustomTable({ headers, children }) {
  return (
    <div className="relative overflow-x-auto w-11/12   custom_table_container spesfic_scrollBar">
      <table className="w-full text-sm text-center text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {headers.map((elem, index) => (
              <th key={index} scope="col" className="px-6 py-3">
                {elem}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="">
          {children}
        </tbody>
      </table>
    </div>
  );
}
