import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../componentes/PageTitle";
import CustomSearch from "../componentes/CustomSearch";
import CustomTable from "../componentes/CustomTable";
import CustomPagination from "../componentes/CustomPagination";
import { Link } from "react-router-dom";
import { ApiContext } from "../ApiContext";
import CustomLoader from "../componentes/CustomLoader";

export default function ViewSpecialOrders() {
  const { dataList, feachList, page } = useContext(ApiContext);
  useEffect(() => {
    feachList();
  }, [page]);

  return (
    <div className="page_container">
    <PageTitle title="طلبات العمولة" />
    <CustomSearch feachList={feachList} btns={filterBtns} />
    <div style={{ height: "400px" }} className="mt-11">
      {dataList.loader.status === "done" ? (
        <CustomTable headers={tableHeader}>
          {dataList.data.map((elem, index) => (
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {index + 1}
              </th>
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:text-sky-400"
              >
                <Link to="/order-detailes">{elem.orderCode}</Link>
              </th>
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {elem.userName}
              </th>
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {elem.description}
              </th>
              <th
                scope="row"
                className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
              >
                {elem.numberOfOrders}
              </th>
              <th
                scope="row"
                className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
              >
                {elem.orderStatus}
              </th>
            </tr>
          ))}
        </CustomTable>
      ) : (
        <CustomLoader loader={dataList.loader} />
      )}
    </div>
    <CustomPagination
      numperOfPages={dataList.lastPage}
      loader={dataList?.loader}
    />
  </div>
  );
}
const staticAllOrders = [
  {
    _id: "00002",
    orderCode: "#0020",
    description: "وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت",
    numberOfOrders: "15",
    orderStatus: "سابقة",
    userName: "اسم مؤقت",
  },
  {
    _id: "000058",
    orderCode: "#0020",
    description: "وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت2",
    numberOfOrders: "15",
    orderStatus: "سابقة",
    userName: "اسم مؤقت",
  },
  {
    _id: "00002999",
    orderCode: "#0020",
    description: "وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت 3",
    numberOfOrders: "15",
    orderStatus: "سابقة",
    userName: "اسم مؤقت",
  },
  {
    _id: "00002gg8",
    orderCode: "#0020",
    description: "4 وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت",
    numberOfOrders: "15",
    orderStatus: "سابقة",
    userName: "اسم مؤقت",
  },
  {
    _id: "00005glkmlgg88",
    orderCode: "#0020",
    description: "وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت5",
    numberOfOrders: "30",
    orderStatus: "سابقة",
    userName: "اسم مؤقت",
  },
  {
    _id: "00002vvllpv999",
    orderCode: "#0020",
    description: "وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت 6",
    numberOfOrders: "15",
    orderStatus: "سابقة",
    userName: "اسم مؤقت",
  },
  {
    _id: "0rrkc0002",
    orderCode: "#00250",
    description: "وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت",
    numberOfOrders: "15",
    orderStatus: "سابقة",
    userName: "اسم مؤقت",
  },
  {
    _id: "0rrr00058",
    orderCode: "#00870",
    description: "وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت2",
    numberOfOrders: "15",
    orderStatus: "سابقة",
    userName: "اسم مؤقت",
  },
  {
    _id: "000ss02999",
    orderCode: "#008520",
    description: "وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت 3",
    numberOfOrders: "15",
    orderStatus: "سابقة",
    userName: "اسم مؤقت",
  },
  {
    _id: "00002gddg8",
    orderCode: "#0052620",
    description: "4 وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت",
    numberOfOrders: "15",
    orderStatus: "قيد التنفيذ",
    userName: "اسم مؤقت",
  },
  {
    _id: "00005ggg8kk8",
    orderCode: "#0058520",
    description: "وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت5",
    numberOfOrders: "30",
    orderStatus: "سابقة",
    userName: "اسم مؤقت",
  },
  {
    _id: "00002vvddv999",
    orderCode: "#0022320",
    description: "وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت 6",
    numberOfOrders: "15",
    orderStatus: "حالية",
    userName: "اسم مؤقت",
  },
];
const tableHeader = [
  "م",
  "كود الطلب",
  "اسم العميل",
  "وصف الطلب",
  "عدد المنتجات",
  "حالة الطلب",
];
const filterBtns = [
  { text: "جميع الطلبات", key: "all", color: "#0EA5E9" },
  { text: "الحالية", key: "current", color: "#0EA5E9" },
  { text: "السابقة", key: "previous", color: "#0EA5E9" },
  { text: "قيد التنفيذ", key: "Underway", color: "#0EA5E9" },
];
