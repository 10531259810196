import React, { useContext, useEffect, useState } from "react";

import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { ApiContext } from "../ApiContext";

export default function CustomPagination({ numperOfPages, loader }) {
  const { page, setpage } = useContext(ApiContext);
  const [inputPage, setinputPage] = useState(page);
  // hanle change page bu input
  const changePage = (e) => {
    e.preventDefault();
    if (inputPage <= numperOfPages) {
      setpage(inputPage);
    }
  };
  // handle next and prev page
  const prevPage = () => {
    if (page !== 1) {
      setpage(page - 1);
      setinputPage(inputPage - 1);
    }
  };
  const NextPage = () => {
    if (page != numperOfPages) {
      setpage(page + 1);
      setinputPage(page + 1);
    }
  };
  const handleOnChange = (e) => {
    if (e.target.value > numperOfPages) {
    }
    setinputPage(e.target.value);
  };
  useEffect(()=>{
    setinputPage(page)
  },[page])
  return (
    <>
      <div className="pagenation flex justify-center  mt-3 items-center">
        <button
          className="m-2 bg_primary text-white rounded-md p-2 h-10"
          disabled={loader?.status != "done"}
          onClick={prevPage}
          type="button"
        >
          <AiOutlineArrowRight />
        </button>
        <form
          onSubmit={(e) => {
            changePage(e);
          }}
        >
          <input
            className="w-10 my_border rounded-md h-10 text-center m-2 font-bold text_secondary  pagination_input"
            value={inputPage}
            onChange={handleOnChange}
            max={numperOfPages}
            disabled={loader?.status != "done"}
            min="1"
            type="number"
          />
        </form>
        <p>...</p>
        <button
          disabled={loader?.status != "done"}
          className="m-2 bg_primary text-white rounded-md p-2 w-10 h-10 text_sec_hover "
          onClick={() => {
            setpage(numperOfPages);
          }}
          type="button"
        >
          {numperOfPages}
        </button>
        <button
          disabled={loader?.status != "done"}
          className="m-2 bg_primary text-white rounded-md p-2 h-10 text_sec_hover"
          onClick={NextPage}
          type="button"
        >
          <AiOutlineArrowLeft />
        </button>
      </div>
    </>
  );
}
