import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import PageTitle from "../componentes/PageTitle";
import CustomSearch from "../componentes/CustomSearch";
import CustomTable from "../componentes/CustomTable";
import CustomPagination from "../componentes/CustomPagination";
import DeleteConfirmationModal from "../componentes/deleteConfirmationModal";
import { ApiContext } from "../ApiContext";
import CustomLoader from "../componentes/CustomLoader";
import SucsessAlert from "../componentes/SucsessAlert";
import { cutString } from "../globalFunctions/TextModify";
import ActivationBtn from "../componentes/ActivationBtn";
import { myFeach } from "../globalFunctions/Apis";

export default function ViewProducts() {
  const [categories, setcategories] = useState({
    data: [],
    loader: { status: "loading", text: "" },
  });
  const {
    dataList,
    feachList,
    confirmDelete,
    setconfirmDelete,
    openSucsessAlert,
    page,
    openAddModall,
    handleOpenAdddModall,
    handleCloseAdddModall,
  } = useContext(ApiContext);
  const feachCategories = () => {
    setcategories({
      ...dataList,
      loader: { status: "loading", text: `جاري تحميل الأقسام` },
    });
    myFeach("dashboard/category?page=1&limit=1000", "GET")
      .then((data) => {
        if (data.categories.length === 0) {
          setcategories({
            data: data.categories,
            loader: { status: "failed", text: `لا يوجد أقسام لعرضها` },
            lastPage: data.totalPages,
          });
        } else {
          setcategories({
            data: data.categories,
            loader: { status: "done", text: "" },
            lastPage: data.totalPages,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setcategories({
          ...dataList,
          loader: { status: "failed", text: `فشل تحميل الأقسام` },
        });
      });
  };
  useEffect(() => {
    feachList();
  }, [page]);
  useEffect(() => {
    feachCategories();
  }, []);
  return (
    <>
      {confirmDelete?.status && (
        <DeleteConfirmationModal apiPath="dashboard/products" />
      )}
      {openSucsessAlert && <SucsessAlert msg={`تم حذف المنتج بنجاح`} />}
      <div className="page_container">
        <PageTitle title="جميع المنتجات" />
        {categories.loader.status === "done" ? (
          <CustomSearch
            feachList={feachList}
            filterName="category"
            btns={[{ key: "all", text: "كل الأقسام" }, ...categories.data]}
          />
        ) : (
          categories?.loader.text
        )}
        <div style={{ height: "400px" }} className="mt-11">
          {dataList.loader.status === "done" ? (
            <CustomTable headers={tableHeader}>
              {dataList.data.map((elem, index) => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:text-sky-500"
                  >
                    <Link to={`/product-detailes/${elem._id}`}>
                      {elem.name}
                    </Link>
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {elem?.description ? cutString(elem?.description) : "----"}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {elem.categories[0]?.name
                      ? elem.categories[0]?.name
                      : "---------"}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {elem.numberOfRequests}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <ActivationBtn
                      apiPath="dashboard/product"
                      apiType=""
                      toggleKey="isHidden"
                      colorclassName="bg_secondary"
                      data={elem}
                    />
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {elem.price}{" "}
                    <span className="text-xs text-gray-400">ريال</span>
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <Link to={`/add-product/edit/${elem._id}`}>
                      <AiFillEdit color="#22C55E" size={"23px"} />
                    </Link>
                    {/* <button
                      className="mr-2"
                      onClick={() =>
                        setconfirmDelete({
                          status: true,
                          title: "المنتج ",
                          name: elem.name,
                          id: elem._id,
                        })
                      }
                    >
                      <AiFillDelete color="#EF4444" size={"23px"} />
                    </button> */}
                  </th>
                </tr>
              ))}
            </CustomTable>
          ) : (
            <CustomLoader loader={dataList.loader} />
          )}
        </div>
        <CustomPagination
          numperOfPages={dataList.lastPage}
          loader={dataList?.loader}
        />
      </div>
    </>
  );
}

const tableHeader = [
  "م",
  "اسم المنتج",
  "وصف المنتج",
  "القسم",
  "عدد الطلبات",
  "إظهار المنتج",
  "السعر",
  "تعديل",
];
const filterBtns = [
  { text: "كل الأقسام", key: "all", color: "#0EA5E9" },
  { text: "كرسي", key: "chair", color: "#0EA5E9" },
  { text: "كنبة", key: "Couch", color: "#0EA5E9" },
  { text: "غرفة نوم", key: "bedrom", color: "#0EA5E9" },
  { text: "سفرة", key: "table", color: "#0EA5E9" },
];
