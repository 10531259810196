import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiFillDelete, AiTwotoneStar } from "react-icons/ai";
import { FaBan } from "react-icons/fa";
import profile from "../assets/man.png";
import CustomTable from "../componentes/CustomTable";
import BackPageBtn from "../componentes/BackPageBtn";
import DeleteConfirmationModal from "../componentes/deleteConfirmationModal";

export default function UserDetailes() {
  const [userInfo, setuserInfo] = useState(userStaticInfo);
  const [userOrders, setuserOrders] = useState(staticAllOrders);
  const [userSpecialOrders, setuserSpecialOrders] = useState(
    staticAllSpecialOrders
  );
  const [userRates, setuserRates] = useState(staticUserRates);
  //handle delete states
  const [confirmDelete, setconfirmDelete] = useState({
    status: false,
    name: "",
  });
  const [deleteLoader, setdeleteLoader] = useState({ status: "" });

  // handle delete one user
  const handledelete = (_id) => {
    console.log("delete user eith id:", _id);
    setdeleteLoader({ status: "loading" });
    setTimeout(() => {
      setdeleteLoader({ status: "deleted" });
      setconfirmDelete({ status: false, name: "" });
    }, 1000);
  };
  return (
    <>
      {confirmDelete.status && (
        <DeleteConfirmationModal
          setconfirmDelete={setconfirmDelete}
          handledelete={handledelete}
          confirmDelete={confirmDelete}
          loader={deleteLoader}
        />
      )}
      <div className="page_container  ml-12">
        <BackPageBtn pageName="العملاء" path="/view-users" />
        <div className="flex items-center p-5  bg-gray-100 mt-4 relative">
          <figure className="w-40 border-black my_border pt-2">
            <img src={profile} />
          </figure>
          <ul className="mr-6">
            <li className="mt-1">الاسم: {userInfo.name}</li>
            <li className="mt-1">البريد الإلكتروني: {userInfo.email}</li>
            <li className="mt-1">الهاتف: {userInfo.phone}</li>
            <li className="mt-1">العنوان: {userInfo.adress}</li>
            <li className="mt-1">عدد الطلبات: {userInfo.numberOfOrders}</li>
            <li className="mt-1">
              حالة العميل:{" "}
              {userInfo.isbloucked ? (
                <span className="text-red-400">محظور</span>
              ) : (
                <span className="text-green-400">غير محظور</span>
              )}
            </li>
          </ul>
          <div className="flex mt-auto mr-auto">
            <button className="my_btn bg-orange-400 flex items-center mr-2 rounded-md">
              <span className="ml-3">حظر</span> <FaBan />
            </button>
            <button className="my_btn bg-red-500 flex items-center mr-2 rounded-md">
              <AiFillDelete
                onClick={() =>
                  setconfirmDelete({
                    status: true,
                    title: "العميل ",
                    name: userInfo.name,
                    id: userInfo._id,
                  })
                }
              />
            </button>
            {/* <button className="my_btn bg-green-500 flex items-center mr-2 rounded-md">
            <AiFillEdit />
          </button> */}
          </div>
        </div>
        <div className="-ml-24">
          <h2 className="text-center mt-12 -mb-10 text-xl">
            طلبات العميل السابقة
          </h2>
          <CustomTable headers={ordersTableHeader}>
            {userOrders.map((elem, index) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {index + 1}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:text-sky-400"
                >
                  <Link to="/order-detailes">{elem.orderCode}</Link>
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.userName}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.description}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.numberOfProducts}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.orderStatus}
                </th>
              </tr>
            ))}
          </CustomTable>
        </div>
        <div className="-ml-24">
          <h2 className="text-center mt-12 -mb-10 text-xl">طلبات العمولة</h2>
          <CustomTable headers={ordersTableHeader}>
            {userSpecialOrders.map((elem, index) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {index + 1}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:text-sky-400"
                >
                  <Link to="/special-order-detailes">{elem.orderCode}</Link>
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.userName}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.description}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.numberOfProducts}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.orderStatus}
                </th>
              </tr>
            ))}
          </CustomTable>
        </div>
        <div className="-ml-24 ">
          <h2 className="text-center mt-12 -mb-10 text-xl">تقيمات العميل</h2>
          <CustomTable headers={userRatesTableHeader}>
            {userRates.map((elem, index) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {index + 1}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:text-sky-400"
                >
                  <Link to="/special-order-detailes">{elem.productName}</Link>
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.orderCode}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.orderDate}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white flex justify-center"
                >
                  {stars.map((item, starIndex) => (
                    <div
                      className={`${
                        starIndex < elem.rate
                          ? "star_active"
                          : "star_not_active"
                      }`}
                    >
                      <AiTwotoneStar />
                    </div>
                  ))}
                </th>
              </tr>
            ))}
          </CustomTable>
        </div>
        <div className="-ml-24 mb-20">
          <h2 className="text-center mt-12 -mb-10 text-xl">
            المنتجات داخل السلة
          </h2>
          <CustomTable headers={cartProductHeader}>
            {staticUserCartProduct.map((elem, index) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {index + 1}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:text-sky-400"
                >
                  <Link to="/product-detailes">{elem.productName}</Link>
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.descreption}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.date}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.price}{" "}
                  <span className="text-xs text-gray-500">ريال سعودي</span>
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.number}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.number * elem.price}{" "}
                  <span className="text-xs text-gray-500">ريال سعودي</span>
                </th>
              </tr>
            ))}
          </CustomTable>
        </div>
      </div>
    </>
  );
}
const userStaticInfo = {
  name: "ياسر جمال داود",
  email: "testdepax@gmail.com",
  phone: "01000673448",
  adress: "المنصورة-المشاية السفلية-15",
  numberOfOrders: "32",
  isbloucked: false,
};
const staticAllOrders = [
  {
    _id: "00002gddg8",
    orderCode: "#0052620",
    description: "4 وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت",
    numberOfProducts: "15",
    orderStatus: "قيد التنفيذ",
    userName: "اسم مؤقت",
  },
  {
    _id: "00005ggg8kk8",
    orderCode: "#0058520",
    description: "وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت5",
    numberOfProducts: "30",
    orderStatus: "سابقة",
    userName: "اسم مؤقت",
  },
  {
    _id: "00002vvddv999",
    orderCode: "#0022320",
    description: "وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت 6",
    numberOfProducts: "15",
    orderStatus: "حالية",
    userName: "اسم مؤقت",
  },
];
const staticAllSpecialOrders = [
  {
    _id: "00002gddg8",
    orderCode: "#0052620",
    description: "4 وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت",
    numberOfProducts: "15",
    orderStatus: "قيد التنفيذ",
    userName: "اسم مؤقت",
  },
  {
    _id: "00005ggg8kk8",
    orderCode: "#0058520",
    description: "وصف مؤقت وصف مؤقت يمكن استبداله وصف مؤقت وصف مؤقت5",
    numberOfProducts: "30",
    orderStatus: "سابقة",
    userName: "اسم مؤقت",
  },
];
const ordersTableHeader = [
  "م",
  "كود الطلب",
  "اسم العميل",
  "وصف الطلب",
  "عدد المنتجات",
  "حالة الطلب",
];

const userRatesTableHeader = [
  "م",
  "اسم المنتج",
  "كود الطلب",
  "تاريخ الطلب",
  "التقيم",
];
const staticUserRates = [
  {
    productName: "كنبة",
    orderCode: "#125890",
    orderDate: "24/10/2021",
    rate: 3,
  },
  {
    productName: "كنبة",
    orderCode: "#125890",
    orderDate: "24/10/2021",
    rate: 4,
  },
  {
    productName: "كنبة",
    orderCode: "#125890",
    orderDate: "24/10/2021",
    rate: 5,
  },
];
const stars = ["", "", "", "", ""];
const cartProductHeader = [
  "م",
  "المنتج",
  "وصف المنتج",
  "تاريخ الإضافة",
  "سعر المنتج",
  "العدد",
  "السعر الكلي",
];
const staticUserCartProduct = [
  {
    productName: "كرسي",
    descreption: "وصف مؤقت يمكن استبداله وصف مؤقت يمكن استبداله",
    price: 500,
    date: "25/8/2016",
    number: 3,
  },
];
