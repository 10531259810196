import React, { useEffect, useRef, useState } from "react";
import { GrGallery } from "react-icons/gr";
import imge from "../assets/galleryjpg.jpg";
import { postImage } from "../globalFunctions/Apis";
export default function CustomInputImge({
  lable,
  name,
  value,
  handleOnChange,
  imgeUrl = false,
  inputErr,
  apiPath,
  imageAppendKey,
  moreThanOne
}) {
  const imgRef = useRef(null);
  const [uploadeErr, setuploadeErr] = useState(inputErr);
  const [loader, setloader] = useState({ status: "", text: "" });
  // handle uplode book and image
  const handleUplodeImage = async (e) => {
    setloader({ status: "loading", text: "جاري الرفع " });
    let file = await e.target.files[0];
    let Imageformdata = new FormData();
    Imageformdata.append(`${imageAppendKey ? imageAppendKey : "image"}`, file);
    postImage(apiPath, Imageformdata)
      .then((data) => {
        console.log(data)
        imgRef.current.value = "";
        handleOnChange(data.image ? data.image : data.images);
        setloader({ status: "done", text: "" });
        setuploadeErr("");
      })
      .catch((err) => {
        console.log(err);
        setuploadeErr("فشل رفع الصورة حاول مرة أخرى");
        setloader({
          status: "failed",
          // text: JSON.parse(err.message)?.error,
        });
      });
  };
  useEffect(() => {
    setuploadeErr(inputErr);
  }, [inputErr]);
  return (
    <div className="flex  add_img">
      <div className="flex flex-col ">
        <div
          className="relative my_input h-10 w-44 flex items-center justify-between mt-12 px-3 btn"
          onClick={() => imgRef.current.click()}
          role={"button"}
        >
          <label>{lable}</label>
          <input
           multiple={moreThanOne?true:false}
            type="file"
            name={name}
            value={value}
            onChange={handleUplodeImage}
            className="my_input h-10 mt-2 hidden"
            ref={imgRef}
          />
          <div className="">
            <GrGallery className="hover:text-sky-400" />
          </div>
        </div>
        <h6 className="err_messsage">{uploadeErr}</h6>
      </div>
      <figure
        className="h-32 mr-3 image_bg flex items-center justify-center"
        style={{
          width: "180px",
          backgroundImage: `url(${imgeUrl ? imgeUrl : imge})`,
        }}
      >
        {loader.status === "loading" && <div className="loader"></div>}
      </figure>
    </div>
  );
}
