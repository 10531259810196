import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../componentes/CustomTable";
import BackPageBtn from "../componentes/BackPageBtn";
export default function OrderDetailes() {
  const [orderInfo, setorderInfo] = useState(orderStaticInfo);

  const changeOrderStatus = (e) => {
    console.log(e.target.name);
  };
  return (
    <div className="page_container ml-12">
      <BackPageBtn pageName="الطلبات" path="/view-orders" />
      <div className="bg-gray-100 p-5 mt-9 flex">
        <ul>
          <li className="mt-3 ">
            اسم العميل:{" "}
            <Link
              className="my_border px-3 text-black hover:bg-sky-400 hover:text-white"
              to="/user-detailes"
            >
              {" "}
              {orderInfo.userName}
            </Link>
          </li>
          <li className=" mt-3">كود الطلب: {orderInfo.orderCode}</li>
        </ul>
        <ul className="mr-10">
          <li className="mt-3">وقت الطلب: {orderInfo.orderTime}</li>
          <li className="mt-3">وقت التسليم: {orderInfo.dliveryTime}</li>
        </ul>
        <ul className="mr-10">
          <li className="mt-3">حالة الطلب: {orderInfo.status}</li>
        </ul>
      </div>
      <div className="-ml-24 mt-11">
        <h2 className="-mb-10 text-center text-3xl">المنتجات المطلوبة</h2>
        <CustomTable headers={tableHeader}>
          {orderInfo.products.map((elem, index) => (
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {index + 1}
              </th>
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:text-sky-500"
              >
                <Link to="/product-detailes">{elem.productName}</Link>
              </th>
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {elem.descreption}
              </th>
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {elem.price}{" "}
                <span className="text-xs text-gray-400">ريال سعودي</span>
              </th>
              <th
                scope="row"
                className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
              >
                {elem.number}
              </th>
              <th
                scope="row"
                className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
              >
                {elem.number * elem.price}{" "}
                <span className="text-xs text-gray-400">ريال سعودي</span>
              </th>
            </tr>
          ))}
        </CustomTable>
      </div>
      <div className="order_status_btns flex justify-center">
        <button
          name="reject"
          onClick={changeOrderStatus}
          className="my_btn bg-red-500 m-4"
        >
          رفض الطلب
        </button>
        <button
          name="inProgress"
          onClick={changeOrderStatus}
          className="my_btn bg-orange-300 m-4"
        >
          جاري التنفيذ
        </button>
        <button
          name="delivering"
          onClick={changeOrderStatus}
          className="my_btn bg-orange-400 m-4"
        >
          جاري الشحن
        </button>
        <button
          name="dine"
          onClick={changeOrderStatus}
          className="my_btn bg-green-500 m-4"
        >
          تم التوصيل
        </button>
      </div>
    </div>
  );
}
const orderStaticInfo = {
  userName: "اسم مؤقت",
  orderTime: "24/10/2022-13:20",
  orderCode: "#01058",
  status: "تم الشحن",
  dliveryTime: "24/10/2022-13:30",
  products: [
    {
      productName: "غرفة أنتريه",
      descreption: "غرفة أنتريه مكونة من كنبة و أربع كراسي لون أزرق",
      price: "500",
      number: 5,
    },
    {
      productName: "غرفة أنتريه",
      descreption: "غرفة أنتريه مكونة من كنبة و أربع كراسي لون أزرق",
      price: "500",
      number: 5,
    },
    {
      productName: "غرفة أنتريه",
      descreption: "غرفة أنتريه مكونة من كنبة و أربع كراسي لون أزرق",
      price: "500",
      number: 5,
    },
  ],
};
const tableHeader = [
  "م",
  "اسم المنتج",
  "وصف المنتج",
  "سعر الوحدة",
  "العدد",
  "السعر الكلي",
];
