import React from "react";
import PageTitle from "../componentes/PageTitle";
import AllAds from "../componentes/adds/AllAds";
import ApiContextProvider from "../ApiContext";
export default function ViewAds() {
  const apiPath = "dashboard/ad";
  const responceKey = "ads";
  const nameInAr = "إعلانات";
  return (
    <>
      <div className="page_container">
        <PageTitle title="جميع الإعلانات" />
        <ApiContextProvider
          apiPath={apiPath}
          responceKey={responceKey}
          nameInAr={nameInAr}
        >
          <AllAds />
        </ApiContextProvider>
      </div>
    </>
  );
}
