import React from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Sidebar from "./componentes/common/Sidebar";
import ViewUsers from "./pages/ViewUsers";
import ViewProducts from "./pages/ViewProducts";
import ViewOrders from "./pages/ViewOrders";
import ViewSpecialOrders from "./pages/ViewSpecialOrders";
import ViewCategories from "./pages/ViewCategories";
import ViewAds from "./pages/ViewAds";
import UserDetailes from "./pages/UserDetailes";
import ProductDetailes from "./pages/ProductDetailes";
import OrderDetailes from "./pages/OrderDetailes";
import SpecialOrderDetailes from "./pages/SpecialOrderDetailes";
import AddversmentDetailes from "./pages/AddversmentDetailes";
import AddProduct from "./pages/AddProduct";
import AddNotefication from "./pages/AddNotefication";
import ViewNotefications from "./pages/ViewNotefications";
import LogIn from "./pages/LogInPage";
import ProtectedRoute from "./globalFunctions/ProtectedRoute";
import ApiContextProvider from "./ApiContext";
import EditProduct from "./pages/EditProduct";
function App() {
  return (
    <>
      <BrowserRouter>
        <Sidebar />
        <Routes>
          {/* tables pages */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <ViewUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-users"
            element={
              <ProtectedRoute>
                <ViewUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-products"
            element={
              <ProtectedRoute>
                <ApiContextProvider
                  apiPath="dashboard/product"
                  responceKey="products"
                  nameInAr="منتجات"
                >
                  <ViewProducts />
                </ApiContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-orders"
            element={
              <ProtectedRoute>
                <ApiContextProvider
                  apiPath="dashboard/orders"
                  responceKey="orders"
                  nameInAr="طلبات"
                >
                  <ViewOrders />
                </ApiContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-special-orders"
            element={
              <ProtectedRoute>
                <ApiContextProvider
                  apiPath="dashboard/SpecialOrders"
                  responceKey="orders"
                  nameInAr="طلبات"
                >
                  <ViewSpecialOrders />
                </ApiContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-categories"
            element={
              <ProtectedRoute>
                <ViewCategories />
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-ads"
            element={
              <ProtectedRoute>
                <ViewAds />
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-notefications"
            element={
              <ProtectedRoute>
                <ApiContextProvider
                  apiPath="dashboard/notification"
                  responceKey="Notifications"
                  nameInAr="إشعار"
                >
                  <ViewNotefications />
                </ApiContextProvider>
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<LogIn />} />
          {/* detailes pages */}
          <Route
            path="/user-detailes"
            element={
              <ProtectedRoute>
                <UserDetailes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/product-detailes/:id"
            element={
              <ProtectedRoute>
                <ApiContextProvider
                  apiPath="dashboard/product"
                  responceKey="products"
                  nameInAr="منتج"
                >
                  <ProductDetailes />
                </ApiContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/order-detailes"
            element={
              <ProtectedRoute>
                <OrderDetailes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/special-order-detailes"
            element={
              <ProtectedRoute>
                <SpecialOrderDetailes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-detailes/:id"
            element={
              <ProtectedRoute>
                <AddversmentDetailes />
              </ProtectedRoute>
            }
          />
          {/* add form pages */}
          <Route
            path="/add-product"
            element={
              <ProtectedRoute>
                <ApiContextProvider
                  apiPath="dashboard/product"
                  responceKey="products"
                  nameInAr="منتج"
                >
                  <AddProduct />
                </ApiContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-product/edit/:id"
            element={
              <ProtectedRoute>
                <ApiContextProvider
                  apiPath="dashboard/product"
                  responceKey="products"
                  nameInAr="منتج"
                >
                  <EditProduct />
                </ApiContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-notefication"
            element={
              <ProtectedRoute>
                <ApiContextProvider
                  apiPath="dashboard/notification"
                  responceKey="Notifications"
                  nameInAr="إشعار"
                >
                  <AddNotefication />
                </ApiContextProvider>
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

// # Use an official Node.js runtime as the base image
// FROM node:14

// # Set the working directory in the container
// WORKDIR /app

// # Copy the package.json and package-lock.json files to the container
// COPY package*.json ./

// # Install dependencies
// RUN npm install

// # Copy the entire app to the container
// COPY . .

// # Build the React app
// RUN npm run build

// # Expose the port your app will be running on
// EXPOSE 3000

// # Define the command to start your app
// CMD ["npm", "start"]
