import React, { useContext, useEffect } from "react";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ApiContext } from "../../ApiContext";
import CustomSearch from "../CustomSearch";
import CustomTable from "../CustomTable";
import ActivationBtn from "../ActivationBtn";
import CustomLoader from "../CustomLoader";
import CustomPagination from "../CustomPagination";
import DeleteConfirmationModal from "../deleteConfirmationModal";

export default function AllUsers() {
  const {
    dataList,
    feachList,
    imgeModal,
    setimgeModal,
    activeImage,
    setactiveImage,
    confirmDelete,
    setconfirmDelete,
    openSucsessAlert,
    page,
    openAddModall,
    handleOpenAdddModall,
    handleCloseAdddModall,
  } = useContext(ApiContext);
  useEffect(() => {
    feachList("users");
  }, [page]);

  useEffect(() => {
    // handle get all user in component didmount and when update page
    feachList();
  }, [page]);

  return (
    <>
      {confirmDelete?.status && (
        <DeleteConfirmationModal apiPath="dashboard/category" />
      )}
      <CustomSearch feachList={feachList} btns={filterBtns} />
      <div style={{ height: "400px" }} className="mt-11">
        {dataList.loader?.status === "done" ? (
          <CustomTable headers={tableHeader}>
            {dataList.map((elem, index) => (
              <tr
                key={index}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {index + 1}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:text-sky-500"
                >
                  <Link to="/user-detailes">{elem.name}</Link>
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.location}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.numberOfOrders}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.numberOfcartProducts == 0 ? (
                    "السلة فارغة"
                  ) : (
                    <>
                      {elem.numberOfcartProducts}{" "}
                      <span className="text-xs text-gray-500">منتجات</span>
                    </>
                  )}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <ActivationBtn
                    apiPath="dashboard/users"
                    apiType="toggle-blouck"
                    colorclassName="bg_secondary"
                    data={elem}
                    toggleKey="isBloucked"
                  />
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <button
                    className="mr-2"
                    onClick={() =>
                      setconfirmDelete({
                        status: true,
                        title: "العميل ",
                        name: elem.name,
                        id: elem._id,
                      })
                    }
                  >
                    <AiFillDelete color="#EF4444" size={"23px"} />
                  </button>
                </th>
              </tr>
            ))}
          </CustomTable>
        ) : (
          <CustomLoader loader={dataList.loader} />
        )}
      </div>
      <CustomPagination
        numperOfPages={dataList.lastPage}
        loader={dataList?.loader}
      />
    </>
  );
}
const tableHeader = [
  "م",
  "الاسم",
  "العنوان",
  "عدد الطلبات السابقة",
  "عدد المنتجات في السلة",
  "الحالة/(Bloucked)",
  "تعديل",
];
const filterBtns = [
  { text: "الجميع", key: "all", color: "#0EA5E9" },
  { text: "أصحاب السلة المفتوحة", key: "opened", color: "#FB923C" },
  { text: "العملاء المحظورون", key: "bloucked", color: "#EF4444" },
  { text: "العملاء الغير محظورين", key: "vrified", color: "#22C55E" },
];
