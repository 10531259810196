import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AiTwotoneStar } from "react-icons/ai";
import imge from "../assets/detailes.png";
import ProductCard from "../componentes/ProductCard";
import BackPageBtn from "../componentes/BackPageBtn";
import PageTitle from "../componentes/PageTitle";
import CustomTable from "../componentes/CustomTable";
import DeleteConfirmationModal from "../componentes/deleteConfirmationModal";
import { ApiContext } from "../ApiContext";
import CustomLoader from "../componentes/CustomLoader";
export default function ProductDetailes() {
  const { feachById, element } = useContext(ApiContext);
  const parames = useParams("id");
  const [productInfo, setproductInfo] = useState(productStaticInfo);
  const [confirmDelete, setconfirmDelete] = useState({
    status: false,
    name: "",
  });
  const [deleteLoader, setdeleteLoader] = useState({ status: "" });
  // handle delete one user
  const handledelete = (_id) => {
    console.log("delete user eith id:", _id);
    setdeleteLoader({ status: "loading" });
    setTimeout(() => {
      setdeleteLoader({ status: "deleted" });
      setconfirmDelete({ status: false, name: "" });
    }, 1000);
  };
  useEffect(() => {
    feachById(`dashboard/product/${parames.id}`, "product");
  }, []);
  return (
    <>
      <div className="page_container ml-16">
        <div className="">
          <BackPageBtn pageName="المنتجات" path="/view-products" />
        </div>
        <PageTitle title="تفاصيل المنتج" />
        {element.loader.status === "done" ? (
          <>
            <ProductCard editBtns={true} productData={element?.data} />
            <div className="-ml-24 mb-20">
              <h2 className="text-center mt-12 -mb-10 text-xl">
                تقيمات المنتج
              </h2>
              <CustomTable headers={ratesTableHeader}>
                {productInfo.rates.map((elem, index) => (
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {index + 1}
                    </th>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:text-sky-400"
                    >
                      <Link to="/user-detailes">{elem.userName}</Link>
                    </th>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <Link to="/order-detailes">{elem.orderCode}</Link>
                    </th>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {elem.date}
                    </th>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white flex justify-center"
                    >
                      {stars.map((item, starIndex) => (
                        <div
                          className={`${
                            starIndex < elem.rate
                              ? "star_active"
                              : "star_not_active"
                          }`}
                        >
                          <AiTwotoneStar size={"20px"} />
                        </div>
                      ))}
                    </th>
                  </tr>
                ))}
              </CustomTable>
            </div>
          </>
        ) : (
          <CustomLoader loader={element.loader} />
        )}
      </div>
    </>
  );
}

const productStaticInfo = {
  basicInfo: {
    name: "كنبة مربعة مريحة",
    productImges: [imge, imge, imge],
    descrebtion: "وصف للكنبة وصف للكنبة وصف لللكنبة وصف للكنبة",
    deliveryMethod: "طريثة الشحن",
    numberOfprochases: 29,
    addetionalInfo: "معلومات إضافية مؤقتة",
    price: "15000",
    priceBeforeOffer: "12000",
  },
  rates: [
    { userName: "ياسر داود", orderCode: "#1200", date: "12/10/2016", rate: 1 },
    { userName: "ياسر داود", orderCode: "#12005", date: "12/10/2016", rate: 4 },
    { userName: "ياسر داود", orderCode: "#12005", date: "12/10/2016", rate: 5 },
  ],
};
const ratesTableHeader = ["م", "الاسم", "كود الطلب", "تاريخ الشراء", "التقيك"];
const stars = ["", "", "", "", ""];
