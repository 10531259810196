import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../ApiContext";
import { myFeach } from "../globalFunctions/Apis";

export default function ActivationBtn({
  data,
  colorClass,
  apiPath,
  apiType,
  toggleKey,
}) {
  const [loader, setloader] = useState({ status: "", id: "" });
  const [rowData, setrowData] = useState(data);
  // handle toggle an element (activate/hide)
  const handleToggle = (id) => {
    setloader({ status: "loading", id: id });
    myFeach(`${apiPath}/${id}${apiType ? `/${apiType}` : ""}`, "PATCH")
      .then((data) => {
        console.log(data)
        setloader({ status: "done" });
        setrowData({ ...rowData, [toggleKey]: !rowData[toggleKey] });
      })
      .catch((err) => {
        const errMsg = JSON.parse(err.message);
        setloader({
          id: id,
          status: "failed",
          text: errMsg.errors.error ? errMsg.errors.error : "عملية فاشلة",
        });
      });
  };
  const CheckStatus = () => {
    if (toggleKey === "isHidden") {
      return !rowData.isHidden;
    } else {
      return rowData[toggleKey];
    }
  };
  useEffect(() => {
    if (loader.status === "failed") {
      setTimeout(() => {
        setloader({ status: "", id: "" });
      }, 3000);
    }
  }, [loader]);
  return (
    <div className="relative h-full">
      <button
        disabled={loader.status === "loading" && loader.id == rowData._id}
        className={
          CheckStatus() ? `activation_btn ${colorClass}` : "activation_btn_none"
        }
        onClick={() => handleToggle(rowData._id)}
      >
        <div className="dot"></div>
      </button>
      {loader.status === "failed" && loader.id == rowData._id && (
        <h5 className="text-red-400 text_very_small absolute position_center mt-2">{loader.text}</h5>
      )}
    </div>
  );
}
