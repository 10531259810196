import React from "react";
import BackPageBtn from "../componentes/BackPageBtn";
import AdsDetailesCard from "../componentes/adds/AdsDetailesCard";
import ApiContextProvider from "../ApiContext";
export default function AddversmentDetailes() {
  return (
    <div className="page_container">
      <div className="ml-20">
        <BackPageBtn pageName="الإعلانات" path="/view-ads" />
      </div>
      <ApiContextProvider >
        <AdsDetailesCard />
      </ApiContextProvider>
    </div>
  );
}
