import React, { useContext, useEffect } from "react";
import { ApiContext } from "../../ApiContext";
import CustomLoader from "../CustomLoader";
import { useParams } from "react-router-dom";
// import imge from "../assets/adds.png";
export default function AdsDetailesCard() {
  const { feachById, element } = useContext(ApiContext);
  const parames=useParams("id")
  useEffect(() => {
    feachById(`dashboard/ad/${parames.id}`, "ad");
  }, []);
  useEffect(() => {
    console.log("from card", element);
  }, [element]);
  return (
    <>
      {element.loader.status == "done" ? (
        <div
          className=" h-96 bg_cover bg-center mt-10 ml-20 font-bold rounded-md pt-5 pr-4"
          style={{ backgroundImage: `url(${element.data.image})` }}
        >
          <h1 className="text-4xl">{element.data.title}</h1>
          <h2 className="mt-3 text_secondary text-2xl font-medium">
            {element.data.description}
          </h2>
        </div>
      ) : (
        <CustomLoader loader={element.loader} />
      )}
    </>
  );
}
