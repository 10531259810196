import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import CustomLoader from "../CustomLoader";
import AddColorModal from "./AddColorModal";
import { myFeach } from "../../globalFunctions/Apis";
import ActivationBtn from "../ActivationBtn";

export default function ColorsModal({ closeModal, colors, regetColors }) {
  const [openAddColorModal, setopenAddColorModal] = useState({
    status: false,
    id: "",
  });

  const [openSucsessAlert, setopenSucsessAlert] = useState();

  useEffect(() => {
    if (openSucsessAlert == true) {
      setTimeout(() => {
        setopenSucsessAlert(false);
      }, 2000);
    }
  }, [openSucsessAlert]);
  return (
    <div className="fixed w-full h-screen top-0 z-20 right-0">
      <div
        className="w-full h-screen   bg-black opacity-75 modal_escape_bg pointer"
        onClick={closeModal}
      ></div>
      <div className="position_center w-2/4  absolute top-11 bg-white px-20 py-10 mt-40 flex flex-col items-center colors_modal_content">
        {openAddColorModal.status && (
          <AddColorModal
            regetColors={regetColors}
            closeModal={() => setopenAddColorModal({ status: false })}
            isEditPage={openAddColorModal?.id ? true : false}
            colors={colors?.data}
            openAddColorModal={openAddColorModal}
          />
        )}
        <div className="my_border p-6 w-70">
          <h2
            className="w-full text-center bg_secondary my_rounded p-2 text-white pointer"
            onClick={() => setopenAddColorModal({ status: true })}
          >
            الألوان +
          </h2>
          {colors.loader.status === "done" ? (
            <ul>
              {colors?.data.map((elem, index) => (
                <li
                  className="w-60 border_botom py-4 flex items-center justify-between"
                  key={index}
                >
                  <span>{elem.name}</span>
                  <div className="flex items-center justify-between w-2/4">
                    <div className="pointer mx-1 color_activation_Btn">
                      <ActivationBtn
                        apiPath="dashboard/color"
                        apiType=""
                        toggleKey="isHidden"
                        colorclassName="bg_secondary"
                        data={elem}
                      />
                    </div>
                    <div
                      className="pointer mx-1"
                      onClick={() =>
                        setopenAddColorModal({
                          status: true,
                          id: elem._id,
                          color: elem,
                        })
                      }
                    >
                      <AiFillEdit size={25} color="green" />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <CustomLoader loader={colors.loader} />
          )}
        </div>
      </div>
    </div>
  );
}
