import React from "react";
import PageTitle from "../componentes/PageTitle";
import ApiContextProvider from "../ApiContext";
import AllUsers from "../componentes/Users/AllUsers";
const apiPath = "dashboard/category/";
const responceKey = "categories";
const nameInAr = "أقسام";
export default function ViewUsers() {
  const apiPath = "dashboard/admin/getUsers";
  const responceKey = "users";
  const nameInAr = "مستخدمين";
  return (
    <div className="page_container">
      <PageTitle title="جميع العملاء" />
      <ApiContextProvider
        apiPath={apiPath}
        responceKey={responceKey}
        nameInAr={nameInAr}
      >
        <AllUsers />
      </ApiContextProvider>
    </div>
  );
}
