import React, { useContext, useEffect } from "react";
import { ApiContext } from "../../ApiContext";
import CustomTable from "../CustomTable";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import CustomPagination from "../CustomPagination";
import CustomLoader from "../CustomLoader";
import CustomSearch from "../CustomSearch";
import ImgeModal from "../ImgeModal";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import AddCategoryModal from "./AddCategoryModal";
import SucsessAlert from "../SucsessAlert";
import ActivationBtn from "../ActivationBtn";
import { cutString } from "../../globalFunctions/TextModify";
export default function AllCategories() {
  const {
    dataList,
    feachList,
    imgeModal,
    setimgeModal,
    activeImage,
    setactiveImage,
    confirmDelete,
    setconfirmDelete,
    openSucsessAlert,
    page,
    openAddModall,
    handleOpenAdddModall,
    handleCloseAdddModall,
  } = useContext(ApiContext);
  useEffect(() => {
    feachList();
  }, [page]);
  return (
    <>
      {imgeModal?.status && (
        <ImgeModal
          closeModal={() => setimgeModal({ status: false })}
          imges={activeImage}
        />
      )}
      {openSucsessAlert && <SucsessAlert msg={`عملية ناجحة`} />}

      {confirmDelete?.status && (
        <DeleteConfirmationModal apiPath="dashboard/category" />
      )}
      {openAddModall?.status && (
        <AddCategoryModal closeModal={() => handleCloseAdddModall()} />
      )}
      <div className="flex justify-between pl-24">
        <CustomSearch feachList={feachList} btns={false} />
        <button
          className="w-40 h-10 bg_secondary text-white"
          onClick={() => handleOpenAdddModall()}
        >
          + إضافة قسم
        </button>
      </div>
      <div style={{ height: "400px" }} className="mt-11">
        {dataList.loader.status === "done" ? (
          <>
            <CustomTable headers={tableHeader}>
              {dataList.data.map((elem, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {elem.name}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {elem.description ? cutString(elem?.description) : "------"}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {elem.productCount}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <ActivationBtn
                      apiPath="dashboard/category"
                      apiType="toggle-hide"
                      colorclassName="bg_secondary"
                      data={elem}
                      toggleKey="isHidden"
                    />
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <button
                      className="my_btn bg_secondary"
                      onClick={() => {
                        setimgeModal({ status: true });
                        setactiveImage([elem.image]);
                      }}
                    >
                      عرض الصورة
                    </button>
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <button onClick={() => handleOpenAdddModall(elem._id)}>
                      <AiFillEdit color="#22C55E" size={"23px"} />
                    </button>
                    <button className="mr-2">
                      <AiFillDelete
                        onClick={() =>
                          setconfirmDelete({
                            status: true,
                            title: "القسم ",
                            name: elem.categoryName,
                            id: elem._id,
                          })
                        }
                        color="#EF4444"
                        size={"23px"}
                      />
                    </button>
                  </th>
                </tr>
              ))}
            </CustomTable>
          </>
        ) : (
          <CustomLoader loader={dataList.loader} />
        )}
      </div>
      <CustomPagination
        numperOfPages={dataList.lastPage}
        loader={dataList?.loader}
      />
    </>
  );
}
const tableHeader = [
  "م",
  "اسم القسم",
  "وصف القسم",
  " عدد منتجات القسم",
  "تفعيل ",
  "الصورة",
  "حذف القسم",
];
