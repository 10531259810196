import React, { useContext, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { ApiContext } from "../ApiContext";
export default function CustomSearch({ btns, filterName }) {
  const [inputSearch, setinputSearch] = useState("");
  const { setsearchKey, setfilter,filter } = useContext(ApiContext);
  const handleSubmit = (e) => {
    e.preventDefault();
    setsearchKey(inputSearch);
  };
  const handleChangeFilter = (key) => {
    if (key === "all") {
      setfilter({ filterName: "", filterkey: "" });
    } else {
      setfilter({ filterName: filterName, filterkey: key });
    }
  };
  return (
    <div className="filter_bar flex w-11/12 ">
      <form className=" flex items-center w-fit" onSubmit={handleSubmit}>
        <input
          className="search_input "
          onChange={(e) => setinputSearch(e.target.value)}
        />
        <button className="bg_secondary mr-1  p-1 rounded-md" type="submit">
          <BsSearch color="white" size={"20px"} />
        </button>
      </form>
      {btns && (
        <div className="mr-20">
          {btns.map((elem, index) => (
            <button
              className={`my_btn bg-${elem.color?elem.color:" bg_secondary "}-500  rounded-md mr-1 mb-1 ${
                elem.key === filter.filterkey||elem._id===filter.filterkey ? "text_black" : ""
              }${
               ( elem.key === "all"&&filter.filterkey==="") ? "text_black" : ""
              } `}
              style={{ backgroundColor: elem.color }}
              key={index}
              onClick={() => handleChangeFilter(elem.key ? elem.key : elem._id)}
            >
              {elem.text ? elem.text : elem?.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
