import React from "react";
import logo from "../../assets/logoWhite.png";
import { Link, useLocation } from "react-router-dom";
export default function Sidebar() {
  const { pathname } = useLocation();
  const chekIfActiveNac = (navItem) => {
    let flag;
    if (pathname == "/") {
      if (navItem == "/view-users") {
        flag = true;
      }
    }
    if (pathname.includes(navItem)) {
      flag = true;
    }
    return flag;
  };
  return (
    <div className="sidebar_container w-80 bg_primary h-full fixed pt-5 top-0">
      <figure className="w-28 m-auto">
        <img className="block w-full" src={logo} />
      </figure>
      <ul>
        {sideBarItems.map((elem, index) => (
          <li
            className={`t p-2 m-auto w-fit hover:text-cyan-300 ${
              chekIfActiveNac(elem.path) ? "text_secondary" : "text-white"
            }`}
            key={index}
          >
            <Link to={elem.path}>{elem.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

const sideBarItems = [
  { name: "العملاء", path: "/view-users" },
  { name: "المنتجات", path: "/view-products" },
  { name: "إضافة منتج", path: "/add-product" },
  { name: "الطلبات", path: "/view-orders" },
  { name: "طلبات العمولة", path: "/view-special-orders" },
  { name: "الأقسام", path: "/view-categories" },
  // { name: "إضافة قسم", path:"/add-category"},
  { name: "الإعلانات", path: "/view-ads" },
  // { name: "إضافة إعلان", path: "/add-adversement" },
  { name: "الإشعارات", path: "/view-notefications" },
  { name: "إضافة إشعار", path: "/add-notefication" },
];
