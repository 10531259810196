import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
export default function MultiAbleSelect({
  formData,
  setformData,
  options,
  value,
  key,
}) {
  const [selected, setselected] = useState([...value]);
  const [unSelected, setunSelected] = useState([...options.data]);
  const [openList, setopenList] = useState(false);
  const filterTheListIfItsEditPage = (selectedArray, listArray) => {
    const firstIds = selectedArray.map((elem) => elem?._id);
    const filteredArray = listArray.filter(
      (elem) => !firstIds.includes(elem?._id)
    );
    return filteredArray;
  };
  //   handle delete selected options from list if its an edit page
  useEffect(() => {
    if (options.data.length) {
      setunSelected(filterTheListIfItsEditPage(value, options.data));
    }
  }, [options]);
  //   handle update form data according to update in selected list (you can replace it wuth prop function on change to make it dinamic)
  useEffect(() => {
    console.log("hi from use effect selected");
    setformData({
      ...formData,
      categories: selected.map((elem) => {
        return { _id: elem._id, name: elem.name };
      }),
    });
  }, [selected]);
  return (
    <div className="multiable_select_container relative">
      {options.loader.status == "done" ? (
        <>
          <div className="multiAble_Select_Input">
            {selected.map((selectedElem, index) => (
              <div key={index} className="selected_elem">
                <h4 className="title">{selectedElem.name}</h4>
                <div
                  className="esc"
                  onClick={() => {
                    setselected(
                      selected.filter((elem) => elem._id != selectedElem._id)
                    );
                    setunSelected([selectedElem, ...unSelected]);
                  }}
                >
                  x
                </div>
              </div>
            ))}
            <div
              onClick={() => setopenList(!openList)}
              className="pointer open_list_icon"
            >
              <IoIosArrowDown size={25} />
            </div>
          </div>
          {openList && (
            <ul className="select_list">
              {unSelected.length == 0 ? (
                <h5 className="text-sm text-red-400 p-2">
                  لا يوجد خيارات متاحة
                </h5>
              ) : (
                <>
                  {unSelected.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setselected([...selected, option]);
                        setunSelected(
                          unSelected.filter((elem) => elem._id != option._id)
                        );
                      }}
                    >
                      {option.name}
                    </li>
                  ))}
                </>
              )}
            </ul>
          )}
        </>
      ) : (
        <div className="loader_container">
          {options.loader.status == "loading" ? (
            <div className="loader"></div>
          ) : (
            <h5 className="text-xs text-red-400">{options.loader.text}</h5>
          )}
        </div>
      )}
    </div>
  );
}
