import React, { useState } from "react";
import logo from "../assets/logoBlack.png";
import CustomInput from "../componentes/CustomInput";
import { myFeach } from "../globalFunctions/Apis";
import Cookies from "js-cookie";
export default function LogIn() {
  const [formData, setformData] = useState();
  const [loader, setloader] = useState({ status: false, text: "" });
  const [errours, seterrours] = useState({});
  const handleOnchange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setloader({status:true});
    seterrours({ status: false });
    myFeach("dashboard/login", "POST", formData)
      .then(( data ) => {
        console.log(data)
        Cookies.set("token", data.token, { expires: 360 });
        setloader({status:false});
        document.location.href = "/";
      })
      .catch((error) => {
        console.log(error)
        seterrours({ status: true, errours:{genral:"فشل تسجيل الدخول هناك خطأ في البريد الإلكتروني أو الرمز"} });
        setloader({status:false});
      });
  };
  return (
    <div className="login_page w-full h-screen bg_primary fixed">
      <form onSubmit={handleSubmit} className="bg-white h-96 w-1/3 mx-auto mt-28 pt-4">
        <figure className="w-4/5 mx-auto">
          <img className="block w-24 m-auto" src={logo} />
        </figure>
        <div className="w-4/5 mt-3 mx-auto">
          <CustomInput
            lable="اسم الأدمن"
            name="email"
            handleOnChange={handleOnchange}
            type="text"
            lang="en"
          />
          <CustomInput
            lable="رمز الدخول"
            name="password"
            handleOnChange={handleOnchange}
            type="password"
            lang="en"
          />
          <button disabled={loader.status} type="submit" className="my_btn bg_secondary mt-3 w-52 h-10">{loader.status?<div className="loader"></div>:"تسجيل الدخول"}</button>
          <h6 className="err_messsage">{errours?.errours?.genral}</h6>
        </div>
      </form>
    </div>
  );
}
