import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { BsFillSendPlusFill } from "react-icons/bs";
import imge from "../assets/galleryjpg.jpg";
import PageTitle from "../componentes/PageTitle";
import ImgeModal from "../componentes/ImgeModal";
import CustomTable from "../componentes/CustomTable";
import CustomPagination from "../componentes/CustomPagination";
import DeleteConfirmationModal from "../componentes/deleteConfirmationModal";
import CustomLoader from "../componentes/CustomLoader";
import { ApiContext } from "../ApiContext";
import CustomSearch from "../componentes/CustomSearch";
import { cutString, formatDateTime } from "../globalFunctions/TextModify";
export default function ViewNotefications() {
  const {
    dataList,
    feachList,
    imgeModal,
    setimgeModal,
    activeImage,
    setactiveImage,
    page,
  } = useContext(ApiContext);
  useEffect(() => {
    feachList();
  }, [page]);

  return (
    <>
      {imgeModal?.status && (
        <ImgeModal
          closeModal={() => setimgeModal({ status: false })}
          imges={activeImage}
        />
      )}
      <div className="page_container">
        <PageTitle title="جميع الإشعارات" />
        <div className="flex justify-between pl-24">
          <CustomSearch feachList={feachList} btns={false} />
        </div>
        <div style={{ height: "400px" }} className="mt-11">
          {dataList.loader.status == "done" ? (
            <CustomTable headers={tableHeader}>
              {dataList.data.map((elem, index) => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 text-xs font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 text-xs font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {elem.title}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {cutString(elem.description)}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {elem.targetCustomers}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <button
                      className="my_btn bg_secondary"
                      onClick={() => {
                        setimgeModal({ status: true });
                        setactiveImage([elem.image]);
                      }}
                    >
                      عرض الصورة
                    </button>
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {formatDateTime(elem.createdAt).date}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {formatDateTime(elem.createdAt).time}
                  </th>
                  {/* <th
                    scope="row"
                    className="flex px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <Link to="/add-notefication" className="w-fit">
                      <BsFillSendPlusFill color="#00AED7" size={"23px"} />
                    </Link>
                    <button
                      onClick={() =>
                        setconfirmDelete({
                          status: true,
                          title: "الإشعار ",
                          name: elem.title,
                          id: elem._id,
                        })
                      }
                      className="mr-2"
                    >
                      <AiFillDelete color="#EF4444" size={"23px"} />
                    </button>
                  </th> */}
                </tr>
              ))}
            </CustomTable>
          ) : (
            <CustomLoader loader={dataList.loader} />
          )}
        </div>
        <CustomPagination
          numperOfPages={dataList.lastPage}
          loader={dataList?.loader}
        />
      </div>
    </>
  );
}
const staticAllNotefications = [
  {
    title: "خصم 50% ",
    detailes: "خصم 50% على منتجات مختارة لفترة محدودة",
    targetUsers: "الجميع",
    date: "24/5/2022",
    time: "14:20",
    imge: imge,
  },
  {
    title: "خصم 50% ",
    detailes: "خصم 50% على منتجات مختارة لفترة محدودة",
    targetUsers: "العملاء أصحاب السلة المفتوحة",
    date: "24/5/2022",
    time: "14:20",
    imge: imge,
  },
  {
    title: "خصم 50% ",
    detailes: "خصم 50% على منتجات مختارة لفترة محدودة",
    targetUsers: "بعض العملاء",
    date: "24/5/2022",
    time: "14:20",
    imge: imge,
  },
];
const tableHeader = [
  "م",
  "العنوان",
  "الوصف",
  "العملاء المستهدفين",
  "الصورة",
  "تاريخ الإشعار",
  "وقت الإشعار",
  // "تعديل",
];
