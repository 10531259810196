import React, { useContext } from "react";
import { ApiContext } from "../ApiContext";

export default function DeleteConfirmationModal({ apiPath }) {
  const {
    setconfirmDelete,
    confirmDelete,
    handleDelete,
    deleteLoader,
    setdeleteLoader,
  } = useContext(ApiContext);
  const closeModal = () => {
    setconfirmDelete({ status: false, name: "" });
    setdeleteLoader({ status: "" });
  };
  const myHandledelete = (e) => {
    if (e.target.name === "no") {
      closeModal();
      return false;
    } else {
      handleDelete(apiPath);
    }
  };

  return (
    <div className="fixed w-full h-screen top-0 z-20 right-0">
      <button
        className="w-full h-screen   bg-black opacity-75 modal_escape_bg"
        onClick={closeModal}
      ></button>
      <div className="position_center w-1/4  absolute top-11 bg-white px-20 py-10">
        <h1>
          هل أنت متأكد من حذف {confirmDelete.title}؟
          <span className="text_secondary"> {confirmDelete.name}</span>
        </h1>
        <div className="flex justify-center mt-10">
          <button
            onClick={myHandledelete}
            name="no"
            className="my_btn bg-green-500 m-4"
          >
            تراجع
          </button>
          <button
            onClick={myHandledelete}
            name="yes"
            className="my_btn bg-red-500 m-4 w-22 h-8"
          >
            {deleteLoader.status === "loading" ? (
              <div className="loader"></div>
            ) : (
              "نعم"
            )}
          </button>
        </div>
        {deleteLoader.status === "failed" && (
          <h6 className="text-center text-red-400">{deleteLoader.text}</h6>
        )}
      </div>
    </div>
  );
}
