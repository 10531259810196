import React, { useContext, useEffect } from "react";
import CustomPagination from "../CustomPagination";
import ActivationBtn from "../ActivationBtn";
import { Link } from "react-router-dom";
import CustomTable from "../CustomTable";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { ApiContext } from "../../ApiContext";
import CustomSearch from "../CustomSearch";
import CustomLoader from "../CustomLoader";
import ImgeModal from "../ImgeModal";
import AddAdsModal from "./AddAdModal";
import SucsessAlert from "../SucsessAlert";
import { cutString } from "../../globalFunctions/TextModify";

export default function AllAds() {
  const {
    dataList,
    feachList,
    imgeModal,
    setimgeModal,
    activeImage,
    setactiveImage,
    confirmDelete,
    setconfirmDelete,
    page,
    openAddModall,
    handleOpenAdddModall,
    handleCloseAdddModall,
    openSucsessAlert,
  } = useContext(ApiContext);
  useEffect(() => {
    feachList();
  }, [page]);
  return (
    <>
      {confirmDelete?.status && (
        <DeleteConfirmationModal apiPath="dashboard/ad" />
      )}
      {imgeModal?.status && (
        <ImgeModal
          closeModal={() => setimgeModal({ status: false })}
          imges={activeImage}
        />
      )}
      {openAddModall?.status && (
        <AddAdsModal closeModal={() => handleCloseAdddModall()} />
      )}
      {openSucsessAlert && <SucsessAlert msg={`عملية ناجحة`} />}

      <div className="flex justify-between pl-24">
        <CustomSearch
          feachList={feachList}
          btns={filterBtns}
          filterName="ours"
        />
        <button
          className="w-40 h-10 bg_secondary text-white"
          onClick={() => handleOpenAdddModall()}
        >
          + إضافة إعلان
        </button>
      </div>
      <div style={{ height: "400px" }} className="mt-11">
        {dataList.loader.status === "done" ? (
          <CustomTable headers={tableHeader}>
            {dataList.data.map((elem, index) => (
              <tr
                key={index}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {index + 1}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:text-sky-400"
                >
                  <Link to={`/add-detailes/${elem._id}`}>{elem.title?elem?.title:"----"}</Link>
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem?.description ? cutString(elem?.description) : "----"}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.ours ? "إعلان عن منتج" : "إعلان خارج الموقع"}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {elem.clicks}
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <ActivationBtn
                    apiPath="dashboard/ad/toggle-activate"
                    apiType=""
                    toggleKey="active"
                    colorclassName="bg_secondary"
                    data={elem}
                  />
                </th>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <button onClick={() => handleOpenAdddModall(elem._id)}>
                    <AiFillEdit color="#22C55E" size={"23px"} />
                  </button>
                  <button
                    className="mr-2"
                    onClick={() =>
                      setconfirmDelete({
                        status: true,
                        title: "الإعلان ",
                        name: elem.name,
                        id: elem._id,
                      })
                    }
                  >
                    <AiFillDelete color="#EF4444" size={"23px"} />
                  </button>
                </th>
              </tr>
            ))}
          </CustomTable>
        ) : (
          <CustomLoader loader={dataList.loader} />
        )}
      </div>
      <CustomPagination
        numperOfPages={dataList.lastPage}
        loader={dataList?.loader}
      />
    </>
  );
}
const staticAllAds = [
  {
    _id: "0999",
    name: "اسم مؤقت 6",
    title: "عنوان مؤقت 6",
    subTitle: "العنوان الفرعي",
    numberOfClick: 8,
    show: true,
    type: "internal",
  },
  {
    _id: "000v999",
    name: "اسم مؤقت 6",
    title: "عنوان مؤقت 6",
    subTitle: "العنوان الفرعي",
    numberOfClick: 8,
    show: false,
    type: "external",
  },
  {
    _id: "00002vv98",
    name: "اسم مؤقت 6",
    title: "عنوان مؤقت 6",
    subTitle: "العنوان الفرعي",
    numberOfClick: 8,
    show: true,
    type: "internal",
  },
  {
    _id: "0lv999",
    name: "اسم مؤقت 6",
    title: "عنوان مؤقت 6",
    subTitle: "العنوان الفرعي",
    numberOfClick: 8,
    show: true,
    type: "external",
  },
];
const tableHeader = [
  "م",
  "العنوان",
  "العنوان الفرعي",
  "نوع الإعلان",
  "عدد الضغطات",
  "تفعيل الإعلان",
  "تعديل",
];
const filterBtns = [
  { text: "جميع الإعلانات", key: "all", color: "#0EA5E9" },
  { text: "الإعلان عن منتجاتنا", key: true, color: "#7F19E6" },
  { text: "الإعلانات الخارجية", key: false, color: "#22C55E" },
];
