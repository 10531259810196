import React from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import ActivationBtn from "./ActivationBtn";

export default function ProductCard({ productData, editBtns }) {
  return (
    <div className="product card  bg-gray-100 py-8 px-4 rounded-md flex items-center">
      <div className="w-fit">
        <div className="flex">
          <div className="sub_imges flex flex-col justify-between h-72 ml-2 px-1">
            {productData.images.map((elem, index) => (
              <figure className="h-1/4 " key={index}>
                <img className="block h-full rounded-lg" src={elem} />
              </figure>
            ))}
          </div>
          <figure className="main_imge h-72 rounded-md">
            <img
              className="block h-full rounded-md"
              src={productData.images[0]}
            />
          </figure>
        </div>
      </div>
      <div className="mr-5 ">
        <h2 className="text-4xl mb-3 product_title">{productData.name}</h2>
        <ul className="">
          {detailesKeys.map((elem, index) => (
            <span key={index}>
              {productData[elem.key] && (
                <li className="mb-3">
                  {elem.title}: {productData[elem.key]}
                </li>
              )}
            </span>
          ))}
        </ul>
        {editBtns && (
          <div>
            <div className="flex mt-auto mr-auto w-fit">
              <Link
                to={`/add-product/edit/${productData._id}`}
                className="my_btn bg-green-500 flex items-center mr-2 rounded-md"
              >
                <AiFillEdit />
              </Link>
              <div className="mx-2">
                <ActivationBtn
                  apiPath="dashboard/product"
                  apiType=""
                  toggleKey="isHidden"
                  colorclassName="bg_secondary"
                  data={productData}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
const detailesKeys = [
  { key: "description", title: "الوصف" },
  { key: "height", title: "الارتفاع بالمتر" },
  { key: "length", title: "العمق بالمتر" },
  { key: "width", title: "العرض بالمتر" },
  { key: "maxload", title: "قوة التحمل باكيلو" },
  { key: "shippingMethod", title: "طريقة الشحن" },
  { key: "material", title: "نوع الخامة" },
  { key: "price", title: "السعر بالريال" },
  { key: "priceAfterDiscount", title: "السعر بعد الخصم بالريال" },
  { key: "shippingFees", title: "سعر الشحن بالريال" },
];
