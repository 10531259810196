import React, { useEffect, useState } from "react";
import CustomInput from "../CustomInput";
import { myFeach, myPutFeach } from "../../globalFunctions/Apis";
import {
  deleteEmptyKeys,
  validateRequiredInputs,
} from "../../globalFunctions/FormsValidation";
import { MdOutlineDoneOutline } from "react-icons/md";

export default function AddColorModal({
  closeModal,
  regetColors,
  isEditPage,
  colors,
  openAddColorModal,
}) {
  const [formData, setformData] = useState({});
  const [openSucsessAlert, setopenSucsessAlert] = useState();
  const handleOnChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };
  const [submitLoader, setsubmitLoader] = useState({ status: "" });
  const [submitErrs, setsubmitErrs] = useState();
  const add = () => {
    myFeach("dashboard/color", "POST", deleteEmptyKeys(formData), "authrized")
      .then((data) => {
        console.log(data);
        setsubmitLoader({ status: "done" });
        setformData({ name: "", hexacode: "" });
        setsubmitErrs({});
        setopenSucsessAlert(true);
        regetColors();
      })
      .catch((err) => {
        let errMwssage = JSON.parse(err.message)?.errors;
        console.log(errMwssage);
        setsubmitLoader({ status: "done" });
        setsubmitErrs({
          ...errMwssage,
          error: errMwssage.error ? errMwssage.error : "فشل حاول مرة أخرى",
        });
      });
  };
  const edit = () => {
    myPutFeach(`dashboard/color/${openAddColorModal?.id}`, {
      name: formData.name,
      hexacode: formData.hexacode,
    })
      .then((data) => {
        setsubmitLoader({ status: "done" });
        regetColors();
        setopenSucsessAlert(true);
        setTimeout(() => {
          closeModal();
        }, 2000);
      })
      .catch((err) => {
        let errMwssage = JSON.parse(err.message)?.errors;
        console.log(errMwssage);
        setsubmitLoader({ status: "done" });
        setsubmitErrs({
          ...errMwssage,
          error: errMwssage.error ? errMwssage.error : "فشل حاول مرة أخرى",
        });
      });
  };
  const hamdleSubmit = (reqArray) => {
    let errours = validateRequiredInputs(formData, reqArray);
    setsubmitErrs({ ...submitErrs, error: "" });
    if (Object.keys(errours).length > 0) {
      setsubmitErrs(errours);
    } else {
      setsubmitLoader({ status: "loading" });
      if (isEditPage) {
        edit();
      } else {
        add();
      }
    }
  };
  useEffect(() => {
    if (openSucsessAlert == true) {
      setTimeout(() => {
        setopenSucsessAlert(false);
      }, 2000);
    }
  }, [openSucsessAlert]);
  useEffect(() => {
    if (isEditPage) {
      setformData(openAddColorModal?.color);
    }
  }, []);
  return (
    <div className="fixed w-full h-screen top-0 z-20 right-0">
      <div className="position_center w-4/5  absolute top-11 bg-white px-20 py-10 mt-40 box_shadow flex flex-col items-center">
        <button
          className="absolute top-2 left-2 close_add_color_btn"
          onClick={closeModal}
        >
          X
        </button>
        {openSucsessAlert && (
          <div className="fixed w-full h-screen top-0 z-20 right-0">
            <div className="position_center  absolute top-11 bg-white px-20 py-10 shadow-lg">
              <div className="m-auto w-fit">
                <MdOutlineDoneOutline size={50} color="green" />
              </div>
              <h1 className="m-auto">عملية ناجحة</h1>
            </div>
          </div>
        )}
        <form>
          <CustomInput
            lable="اسم اللون"
            name="name"
            value={formData?.name}
            // required={true}
            inputErr={submitErrs?.name}
            type="text"
            handleOnChange={handleOnChange}
          />
          <CustomInput
            lable="كود اللون"
            name="hexacode"
            value={formData?.hexacode}
            inputErr={submitErrs?.hexacode}
            // required={true}
            type="text"
            handleOnChange={handleOnChange}
          />
          <button
           type="button"
            onClick={() => hamdleSubmit(["name", "hexacode"])}
            className="bg_secondary text-white w-36 h-10 p-2 mt-4"
          >
            {submitLoader.status === "loading" ? (
              <div className="loader"></div>
            ) : (
              <>{openAddColorModal.id ? "تعديل" : "إضافة"} </>
            )}
          </button>

          <h6 className="text-red-400 text-xs">
            {submitErrs?.error && submitErrs?.error}
          </h6>
        </form>
      </div>
    </div>
  );
}
