import React, { useState } from "react";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
export default function ImgeModal({ imges,closeModal }) {
  const [imgeIndex, setimgeIndex] = useState(0);
  const nextImge=()=>{
    if(imgeIndex<imges.length-1){
        setimgeIndex(imgeIndex+1)
    }else{
        setimgeIndex(0)
    }
  }
  const prevImge=()=>{
    if(imgeIndex>0){
        setimgeIndex(imgeIndex-1)
    }else{
        setimgeIndex(imges.length-1)
    }
  }
  return (
    <>
      <div className="fixed w-full h-screen top-0 left-0 z-20">
        <button onClick={closeModal} className="w-full h-screen   bg-black opacity-90"></button >
        <figure className="position_center  absolute top-11 flex items-center justify-center">
          {imges.length>1&&
          <button onClick={nextImge} >
            <FaArrowCircleRight size={"50px"} color="#00AED7" />
          </button>
          }
          <img className=" h-96 mx-4" src={imges[imgeIndex]} />
          {imges.length>1&&
          <button onClick={prevImge}>
            <FaArrowCircleLeft size={"50px"} color="#00AED7" />
          </button>
          }
        </figure>
      </div>
    </>
  );
}
