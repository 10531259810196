export function cutString(str) {
  if (str?.length <= 50) {
    return str;
  } else {
    return str.slice(0, 50) + "...";
  }
}
export function  formatDateTime(dateTime) {
  const date = new Date(dateTime);
  
  const optionsDate = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  
  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  const formattedDate = date.toLocaleDateString(undefined, optionsDate);
  const formattedTime = date.toLocaleTimeString(undefined, optionsTime);

  return { date: formattedDate, time: formattedTime };
}
