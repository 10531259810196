import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../PageTitle";
import CustomInput from "../CustomInput";
import CustomInputImge from "../CustomInputImge";
import { ApiContext } from "../../ApiContext";
import CustomLoader from "../CustomLoader";
import SucsessAlert from "../SucsessAlert";
export default function AddAdsModal({ closeModal }) {
  const {
    formData,
    submitErrs,
    submitLoader,
    handleOnChangeInput,
    hamdleSubmit,
    openAddModall,
    feachById,
    element,
    openSucsessAlert,
  } = useContext(ApiContext);

  useEffect(() => {
    if (openAddModall.id) {
      feachById(`dashboard/ad/${openAddModall.id}`, "ad");
    }
  }, []);
  return (
    <div className="modal_container">
      {openSucsessAlert && (
        <SucsessAlert
          msg={`تم ${openAddModall.id ? " تعديل" : " إضافة"} الإعلان بنجاح `}
        />
      )}
      <button onClick={closeModal} className="modal_bg"></button>
      <div className="esc_modal">
        <button onClick={closeModal}>x</button>
      </div>
      <div className="modal_content scroler_hide">
        <PageTitle title="إضافة إعلان جديد" />
        {element.loader.status == "done" ? (
          <form
            onSubmit={(e) =>
              hamdleSubmit(
                e,
                "dashboard/ad",
                { title: "", image: "",description:"", link:"",ours:"" },
                "ad",[ "image","ours","link"]
              )
            }
            className="add_form"
          >
            <div className="form_row">
              <CustomInput
                lable="اسم الإعلان"
                name="title"
                inputErr={submitErrs?.title}
                value={formData?.title}
                type="text"
                handleOnChange={handleOnChangeInput}
              />
              <CustomInput
                lable="وصف الإعلان"
                name="description"
                inputErr={submitErrs?.description}
                value={formData?.description}
                type="textArea"
                handleOnChange={handleOnChangeInput}
              />
            </div>
            <div className="form_row">
              <CustomInput
                lable="رابط الإعلان"
                name="link"
                inputErr={submitErrs?.link}
                value={formData?.link}
                type="text"
                handleOnChange={handleOnChangeInput}
              />
              <CustomInput
                lable="نوع الإعلان"
                name="ours"
                inputErr={submitErrs?.ours}
                value={ formData?.ours}
                type="select"
                handleOnChange={handleOnChangeInput}
                options={[
                  { title: "", value: "" },
                  { title: "إعلان عن منتج داخل الموقع", value: true },
                  { title: "إعلان من خدمة أو منتج خارج الموقع", value: false },
                ]}
              />
            </div>
            <div className="form_row">
              <CustomInputImge
                apiPath="dashboard/ad/image"
                imgeUrl={formData.image}
                handleOnChange={(img) =>
                  handleOnChangeInput({ target: { value: img, name: "image" } })
                }
                inputErr={submitErrs?.image}
                lable="إضافة صورة"
              />
            </div>
            <button
              className="h-11"
              disabled={submitLoader.status === "loading"}
            >
              {submitLoader.status === "loading" ? (
                <div className="loader"></div>
              ) : (
                <>{openAddModall.id ? "تعديل" : "إضافة"}</>
              )}
            </button>
            <h4 className="err_messsage">{submitErrs?.error}</h4>
          </form>
        ) : (
          <CustomLoader loader={element.loader} />
        )}
      </div>
    </div>
  );
}
