import React, { useEffect, useState } from "react";
import { myFeach } from "../../globalFunctions/Apis";
import ColorsModal from "./ColorsModal";
import { AiFillEdit } from "react-icons/ai";
import CustomInput from "../CustomInput";
import CustomLoader from "../CustomLoader";
import { MdDelete } from "react-icons/md";
export default function SelectColor({ itemColors, setitemColors, errour }) {
  const [colors, setcolors] = useState({
    data: [],
    loader: { status: "loading", text: "جاري تحميل الألوان" },
  });
  const [openColorsModal, setopenColorsModal] = useState(false);
  const habdleGetAllColors = () => {
    setcolors({
      ...colors,
      loader: { status: "loading", text: "جاري تحميل الألوان" },
    });
    myFeach(`dashboard/color?isHidden=false`, "GET")
      .then((data) => {
        if (data.colors?.length === 0) {
          setcolors({
            data: data.colors,
            loader: { status: "failed", text: `لا يوجد ألوان` },
          });
        } else {
          setcolors({
            data: data.colors,
            loader: { status: "done", text: "" },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setcolors({
          ...colors,
          loader: { status: "failed", text: `فشل تحميل الألوان` },
        });
      });
  };
  const handleDeleteOverLabColor = (outerIndex, innerIndex) => {
    const updatedItemColors = [...itemColors];
    const innerArray = updatedItemColors[outerIndex];
    if (innerArray && innerIndex >= 0 && innerIndex < innerArray.length) {
      innerArray.splice(innerIndex, 1);
      setitemColors(updatedItemColors);
    }
  };
  const handleAddElement = (outerIndex) => {
    const updatedItemColors = [...itemColors];
    const innerArray = updatedItemColors[outerIndex];
    if (innerArray) {
      innerArray.push("");
      setitemColors(updatedItemColors);
    }
  };
  const handleOnchangeColor = (outerIndex, innerIndex, e) => {
    let colorsTempValue = [...itemColors];
    colorsTempValue[outerIndex][innerIndex] = e.target.value;
    setitemColors([...colorsTempValue]);
  };
  useEffect(() => {
    habdleGetAllColors();
  }, []);
  useEffect(() => {
    // console.log(itemColors);
  }, [itemColors]);
  return (
    <>
      {openColorsModal && (
        <ColorsModal
          colors={colors}
          closeModal={() => setopenColorsModal(false)}
          regetColors={habdleGetAllColors}
        />
      )}
      <div className="form_row flex-wrap select_colors_container">
        <div className="w-full flex justify-center">
          <h3 className=" text-lg text-center">الألوان</h3>
          <div
            className="mx-3 pointer "
            onClick={() => setopenColorsModal(true)}
          >
            <AiFillEdit color="green" size={25} />
          </div>
        </div>

        {colors?.loader?.status === "done" ? (
          <div className="flex flex-wrap justify-start ">
            {itemColors.map((versionElem, versionIndex) => (
              <div
                className=" my_border m-2 p-2 version relative"
                key={versionIndex}
              >
                {versionIndex != 0 && (
                  <div
                    className="absolute top-1 left-1 pointer"
                    onClick={() =>
                      setitemColors(
                        itemColors.filter(
                          (elem, index) => index != versionIndex
                        )
                      )
                    }
                  >
                    X
                  </div>
                )}
                {versionElem.map((elem, index) => (
                  <div key={index} className="items-center flex color_element">
                    <CustomInput
                      // lable="اللون"
                      name="color"
                      // value={formData?.category}
                      required={true}
                      value={
                        itemColors[versionIndex][index]?._id
                          ? itemColors[versionIndex][index]?._id
                          : itemColors[versionIndex][index]
                      }
                      type="select"
                      options={[{ name: "", _id: "" }, ...colors.data]}
                      handleOnChange={(e) =>
                        handleOnchangeColor(versionIndex, index, e)
                      }
                    />
                    {index != 0 && (
                      <button
                        className="delete_color"
                        type="button"
                        onClick={() =>
                          handleDeleteOverLabColor(versionIndex, index)
                        }
                      >
                        <MdDelete color="white" size={20} />
                      </button>
                    )}
                  </div>
                ))}
                {versionElem.length <= 2 && (
                  <button
                    className="add_Sub_color"
                    onClick={() => handleAddElement(versionIndex)}
                    type="button"
                  >
                    إدراج لون مدمج
                  </button>
                )}
              </div>
            ))}
            <button
              className="add_main_color"
              type="button"
              onClick={() => setitemColors([...itemColors, [""]])}
            >
              إدراج لون أساسي
            </button>
          </div>
        ) : (
          <CustomLoader loader={colors.loader} />
        )}
        <h5 className="text-xs text-red-400 w-full">{errour && errour}</h5>
      </div>
    </>
  );
}
