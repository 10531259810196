import Cookies from "js-cookie";
const API_URL = process.env.REACT_APP_API_URL;
const TOKEN = Cookies.get("token");
// ===============================start post method
// handle format api body and api header for post methods
var myHeaders = new Headers();
const restoreHeader = () => {
  myHeaders.forEach((value, header) => {
    myHeaders.delete(header);
  });
};
const requestOptions = (bodyObject, methodType, headerType) => {
  let myReqOptions;
  if (methodType === "POST") {
    restoreHeader();
    myHeaders.append("Content-Type", "application/json");
    headerType === "authrized" &&
      myHeaders.append("Authorization", `Bearer ${TOKEN}`);
    let body = JSON.stringify(bodyObject);
    myReqOptions = {
      method: "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
  } else if (
    methodType === "GET" ||
    methodType === "PUT" ||
    methodType === "DELETE"||
    methodType==="PATCH"
  ) {
    restoreHeader();
    myHeaders.append("Authorization", `Bearer ${TOKEN}`);
    headerType === "json" &&
      myHeaders.append("Content-Type", "application/json");
    myReqOptions = {
      method: methodType,
      headers: myHeaders,
      redirect: "follow",
    };
  }
  return myReqOptions;
};
// handle GENRAL fech method
export const myFeach = (url, methodType, bodyObject, headerType) =>
  fetch(`${API_URL}/${url}`, requestOptions(bodyObject, methodType, headerType))
    .then((response) => response.json())
    .then((result) => {
      restoreHeader();
      if (result.error || result.errors || result.err||result.status==404) {
        if (result.status == 401 && window.location.pathname != "/login") {
          Cookies.remove("token");
          window.location.href = "/login";
        } else {
          throw new Error(JSON.stringify(result));
        }
      } else {
        return result;
      }
    });
// handle PUT method
const handlePutHeaders = (myBody) => {
  restoreHeader();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${TOKEN}`);
  let requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify(myBody),
    redirect: "follow",
  };
  return requestOptions;
};
export const myPutFeach = (url, myBody) =>
  fetch(`${API_URL}/${url}`, handlePutHeaders(myBody))
    .then((response) => response.json())
    .then((result) => {
      if (result.error || result.errors || result.err) {
        throw new Error(JSON.stringify(result));
      } else {
        return result;
      }
    });
// uplode image
const ImagerequestOptions = (bodyObject) => {
  let myReqOptions;
  restoreHeader();
  myHeaders.append("Authorization", `Bearer ${TOKEN}`);
  myReqOptions = {
    method: "POST",
    headers: myHeaders,
    body: bodyObject,
    redirect: "follow",
  };

  return myReqOptions;
};
export const postImage = (url, bodyObject) =>
  fetch(`${API_URL}/${url}`, ImagerequestOptions(bodyObject))
    .then((response) => response.json())
    .then((result) => {
      if (result.error || result.errors || result.err||result.status==404) {
        throw new Error(JSON.stringify(result));
      } else {
        return result;
      }
    });

// var pachHeaders = new Headers();
// pachHeaders.append(
//   "Authorization",
//   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1NjMzOGQyYzdkYzYyZThmNzQ2Mzg5ZiIsImlhdCI6MTcwMTA4MzQwNSwiZXhwIjoxNzMyNjQxMDA1fQ.xR30H8IG_Mu8Y6UEHheGKYt9sEU38dgQNHbNa_lpsQY"
// );

// var pachReqOptions = {
//   method: "PATCH",
//   headers: myHeaders,
//   redirect: "follow",
// };

// fetch(
//   "https://nano-ecommece.onrender.com/dashboard/category/65744eafe9e1dc9bfc4858cb/toggle-hide",
//   requestOptions
// )
//   .then((response) => response.text())
//   .then((result) => console.log(result))
//   .catch((error) => console.log("error", error));
