import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../PageTitle";
import CustomInput from "../CustomInput";
import CustomInputImge from "../CustomInputImge";
import { ApiContext } from "../../ApiContext";
import CustomLoader from "../CustomLoader";
import SucsessAlert from "../SucsessAlert";
export default function AddCategoryModal({ closeModal }) {
  const {
    formData,
    submitErrs,
    submitLoader,
    handleOnChangeInput,
    hamdleSubmit,
    openAddModall,
    feachById,
    element,
    openSucsessAlert,
  } = useContext(ApiContext);

  useEffect(() => {
    if (openAddModall.id) {
      feachById(`dashboard/category/${openAddModall.id}`, "category");
    }
  }, []);
  return (
    <div className="modal_container">
      {openSucsessAlert && <SucsessAlert msg={`تم ${openAddModall.id?" تعديل":" إضافة" } القسم بنجاح `} />}
      <button onClick={closeModal} className="modal_bg"></button>
      <div className="esc_modal">
        <button onClick={closeModal}>x</button>
      </div>
      <div className="modal_content scroler_hide">
        <PageTitle title="إضافة قسم جديد" />
        {element.loader.status == "done" ? (
          <form
            onSubmit={(e) =>
              hamdleSubmit(e,"dashboard/category/", { name: "", image: "", description: "" },"Category",["name", "image"])
            }
            className="add_form"
          >
            <div className="form_row">
              <CustomInput
                lable="اسم القسم"
                name="name"
                inputErr={submitErrs?.name}
                value={formData?.name}
                type="text"
                handleOnChange={handleOnChangeInput}
              />
              <CustomInput
                lable="وصف القسم"
                name="description"
                inputErr={submitErrs?.description}
                value={formData?.description}
                type="textArea"
                handleOnChange={handleOnChangeInput}
              />
            </div>
            <div className="form_row">
              <CustomInputImge
                apiPath="dashboard/category/upload-image"
                imgeUrl={formData.image}
                // handleOnChange={(img) => setformData({ ...formData, image: img })}
                handleOnChange={(img) =>
                  handleOnChangeInput({ target: { value: img, name: "image" } })
                }
                inputErr={submitErrs?.image}
                lable="إضافة صورة"
              />
            </div>
            <button
              className="h-11"
              disabled={submitLoader.status === "loading"}
            >
              {submitLoader.status === "loading" ? (
                <div className="loader"></div>
              ) : (
                <>{openAddModall.id?"تعديل":"إضافة"}</>
              )}
            </button>
            <h4 className="err_messsage">{submitErrs?.error}</h4>
          </form>
        ) : (
          <CustomLoader loader={element.loader} />
        )}
      </div>
    </div>
  );
}
