import React from "react";
import { useContext } from "react";
import { MdOutlineDoneOutline } from "react-icons/md";
import { ApiContext } from "../ApiContext";
export default function SucsessAlert({ msg }) {
  const { openSucsessAlert, setopenSucsessAlert } = useContext(ApiContext);
  return (
    <div className="fixed w-full h-screen top-0 z-20 right-0">
      <button
        onClick={() => setopenSucsessAlert(false)}
        className="w-full h-screen  opacity-75 modal_escape_bg modal_escape_bg"
      ></button>
      <div className="position_center  absolute top-11 bg-white px-20 py-10 shadow-lg mt-40">
        <div className="m-auto w-fit">
          <MdOutlineDoneOutline size={50} color="green" />
        </div>
        <h1 className="m-auto">{msg}</h1>
      </div>
    </div>
  );
}
