import React from "react";
import PageTitle from "../componentes/PageTitle";
import AllCategories from "../componentes/categories/AllCategories";
import ApiContextProvider from "../ApiContext";
export default function ViewCategories() {
  // the context probs
  const apiPath = "dashboard/category/";
  const responceKey = "categories";
  const nameInAr = "أقسام";
  return (
    <>
      <div className="page_container">
        <PageTitle title="الأقسام" />
        <ApiContextProvider
          apiPath={apiPath}
          responceKey={responceKey}
          nameInAr={nameInAr}
        >
          <AllCategories />
        </ApiContextProvider>
      </div>
    </>
  );
}
