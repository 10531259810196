import React, { createContext, useEffect, useState } from "react";
import { myFeach, myPutFeach } from "./globalFunctions/Apis";
import {
  deleteEmptyKeys,
  validateRequiredInputs,
} from "./globalFunctions/FormsValidation";
import { useParams } from "react-router-dom";
export const ApiContext = createContext();
const ApiContextProvider = ({ children, apiPath, responceKey, nameInAr }) => {
  const [componentStarted, setcomponentStarted] = useState(false);
  //=========================== list states
  const [dataList, setdataList] = useState({
    data: [],
    loader: { status: "loading", text: "" },
  });
  const [page, setpage] = useState(1);
  const [searchKey, setsearchKey] = useState("");
  const [filter, setfilter] = useState({ filterName: "", filterkey: "" });
  const [imgeModal, setimgeModal] = useState({ status: false, imges: [] });
  const [activeImage, setactiveImage] = useState([]);
  // ========================element by id state
  const parames = useParams("id");
  const [element, setelement] = useState({
    data: "",
    loader: { status: "loading", text: "" },
  });
  //===================handle delete states
  const [confirmDelete, setconfirmDelete] = useState({
    status: false,
    name: "",
  });
  const [deleteLoader, setdeleteLoader] = useState({ status: "" });
  const [openAddModall, setopenAddModall] = useState({
    status: false,
    type: "",
    id: "",
  });
  // ============handle open sucsess alert
  const [openSucsessAlert, setopenSucsessAlert] = useState(false);
  //============= handle add new element states
  const [formData, setformData] = useState({
    description: "",
    image: "",
  });
  const [submitLoader, setsubmitLoader] = useState({ status: "", text: "" });
  const [submitErrs, setsubmitErrs] = useState({});
  // =====================================================================================end statess==========
  // handle feach a list (category,product,...)
  const feachList = () => {
    setdataList({
      ...dataList,
      loader: { status: "loading", text: `جاري تحميل ال${nameInAr}` },
    });
    myFeach(
      `${apiPath}?page=${page}&limit=6${
        searchKey ? `&search=${searchKey}` : ""
      }${
        filter.filterkey ? `&${filter.filterName}=${filter.filterkey}` : ""
      }  `,
      "GET"
    )
      .then((data) => {
        if (data[responceKey]?.length === 0) {
          setdataList({
            data: data[responceKey],
            loader: { status: "failed", text: `لا يوجد ${nameInAr} لعرضها` },
            lastPage: data.totalPages,
          });
        } else {
          setdataList({
            data: data[responceKey],
            loader: { status: "done", text: "" },
            lastPage: data.totalPages,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setdataList({
          ...dataList,
          loader: { status: "failed", text: `فشل تحميل ال${nameInAr}` },
        });
      });
  };
  // handle delete an element (category,product,...)
  const handleDelete = (apiPath) => {
    setdeleteLoader({ status: "loading" });
    myFeach(`${apiPath}/${confirmDelete.id}`, "DELETE")
      .then((data) => {
        console.log(data);
        setdeleteLoader({ status: "done" });
        feachList();
        setconfirmDelete({ status: false });
        setopenSucsessAlert(true);
      })
      .catch((err) => {
        let errMwssage = JSON.parse(err.message).errors;
        let errMessageType2 = JSON.parse(err.message);
        console.log("errour:", JSON.parse(err.message));
        setdeleteLoader({
          status: "failed",
          text: errMwssage ? errMwssage.error : errMessageType2.msg,
        });
      });
  };
  // handle add an element (category,product,...)
  const handleOpenAdddModall = (id = null) => {
    setsubmitErrs({});
    setopenAddModall({ status: true, id: id });
  };
  const handleCloseAdddModall = () => {
    setformData({});
    setopenAddModall({ status: false, id: null });
  };
  const handleOnChangeInput = (e) => {
    if (submitErrs[e.target.name]) {
      const { [e.target.name]: deletedKey, ...newState } = submitErrs;
      setsubmitErrs({ ...newState });
    }
    setformData({ ...formData, [e.target.name]: e.target.value });
  };
  // handle edit
  const edit = (apiPath, id, regetObjKey, itsModal = true) => {
    myPutFeach(`${apiPath}/${id}`, formData)
      .then((data) => {
        console.log(data);
        setsubmitLoader({ status: "done" });
        if (itsModal) {
          feachList();
          setopenSucsessAlert(true);
          setTimeout(() => {
            handleCloseAdddModall();
          }, 2000);
        } else {
          setopenSucsessAlert(true);
          feachById(`${apiPath}/${id}`, regetObjKey);
        }
      })
      .catch((err) => {
        let errMwssage =
          err.message == "Failed to fetch"
            ? { error: "تحقق من الاتصال بالإنترنت" }
            : JSON.parse(err.message).errors;
        console.log(errMwssage);
        setsubmitLoader({ status: "done" });
        setsubmitErrs({
          ...errMwssage,
          error: errMwssage.error ? errMwssage.error : "فشل التعديل",
        });
      });
  };
  const add = (apiPath, formInetialValue) => {
    console.log("hi from add");
    myFeach(apiPath, "POST", deleteEmptyKeys(formData), "authrized")
      .then((data) => {
        console.log(data);
        setsubmitLoader({ status: "done" });
        setformData({ ...formInetialValue });
        setopenSucsessAlert(true);
        feachList();
      })
      .catch((err) => {
        let errMwssage =
        err.message == "Failed to fetch"
          ? { error: "تحقق من الاتصال بالإنترنت" }
          : JSON.parse(err.message).errors;
        console.log(errMwssage);
        setsubmitLoader({ status: "done" });
        setsubmitErrs({
          ...errMwssage,
          error: errMwssage.error ? errMwssage.error : "فشل الإضافة",
        });
      });
  };
  const hamdleSubmit = (
    e,
    apiPath,
    formInetialValue,
    regetObjKey,
    reqArray,
    itsModal
  ) => {
    e.preventDefault();
    let errours = validateRequiredInputs(formData, reqArray);
    setsubmitErrs({ ...submitErrs, error: "" });
    if (Object.keys(errours).length > 0) {
      setsubmitErrs(errours);
    } else {
      setsubmitLoader({ status: "loading" });
      if (openAddModall.id) {
        edit(apiPath, openAddModall.id, regetObjKey, itsModal);
      } else {
        add(apiPath, formInetialValue);
      }
    }
  };
  // handle get spesfic element with id
  const feachById = (apiPath, objKey) => {
    setelement({
      data: {},
      loader: { status: "loading", text: "جاري تحميل البيانات" },
    });
    myFeach(apiPath, "GET")
      .then((data) => {
        setelement({
          data: data[objKey],
          loader: { status: "done", text: "" },
        });
        setformData({ ...formData, ...data[objKey] });
      })
      .catch((err) => {
        console.log(err);
        setelement({
          loader: { status: "failed", text: "فشل تحميل البيانات" },
        });
      });
  };
  useEffect(() => {
    if (openSucsessAlert) {
      setTimeout(() => {
        setopenSucsessAlert(false);
      }, 2000);
    }
  }, [openSucsessAlert]);
  useEffect(() => {
    if (componentStarted) {
      if (page == 1) {
        feachList();
      } else {
        // when page changed the api will recall in the page
        setpage(1);
      }
    } else {
      setcomponentStarted(true);
    }
  }, [searchKey, filter]);

  return (
    <ApiContext.Provider
      value={{
        dataList,
        feachList,
        page,
        setsearchKey,
        setfilter,
        filter,
        setpage,
        imgeModal,
        setimgeModal,
        activeImage,
        setactiveImage,
        confirmDelete,
        setconfirmDelete,
        deleteLoader,
        setdeleteLoader,
        openAddModall,
        handleDelete,
        formData,
        setformData,
        submitErrs,
        setsubmitErrs,
        submitLoader,
        handleOnChangeInput,
        hamdleSubmit,
        setopenAddModall,
        handleOpenAdddModall,
        handleCloseAdddModall,
        openAddModall,
        feachById,
        element,
        openSucsessAlert,
        setopenSucsessAlert,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContextProvider;
