const checkIfKeyHaveValue = (value) => {
  let flag = true;
  if (value === "" || value === undefined || value === "" || value === null) {
    flag = true;
  } else {
    flag = false;
  }
  return flag;
};
export const validateRequiredInputs = (formObject, reqInputs) => {
  const emptyInputs = {};
  for (let key of reqInputs) {
    if (checkIfKeyHaveValue(formObject[key]) && reqInputs.includes(key)) {
      emptyInputs[key] = "هذا الحقل مطلوب";
    }
  }
  return Object.keys(emptyInputs).length > 0 ? emptyInputs : false;
};
const handleReformatTowdimensionArray = (array) => {
  let updatedArray = array.filter(
    (subArray) => subArray.length > 0 && subArray.some((value) => !!value)
  );
  for (let i = 0; i < updatedArray.length; i++) {
    updatedArray[i] = updatedArray[i].filter((value) => value);
  }
  return updatedArray;
};
export function deleteEmptyKeys(obj) {
  let tempObj = obj;
  Object.keys(tempObj).forEach((key) => {
    if (!tempObj[key]&&key!="addDiscount") {
      delete tempObj[key];
    }
    if (Array.isArray(tempObj[key])) {
      tempObj[key] = tempObj[key].filter((elem, index) => !!elem);
    }
    if (key == "colors") {
      tempObj[key] = handleReformatTowdimensionArray(tempObj[key]);
    }
  });
  return tempObj;
}
