import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../componentes/PageTitle";
import CustomInput from "../componentes/CustomInput";
import CustomInputImge from "../componentes/CustomInputImge";
import SelectColor from "../componentes/products/SelectColor";
import { ApiContext } from "../ApiContext";
import SucsessAlert from "../componentes/SucsessAlert";
import { myFeach } from "../globalFunctions/Apis";
import { useParams } from "react-router-dom";
import CustomLoader from "../componentes/CustomLoader";
import MultiAbleSelect from "../componentes/MultiAbleSelect";
export default function AddProduct() {
  const {
    formData,
    setformData,
    submitErrs,
    submitLoader,
    handleOnChangeInput,
    hamdleSubmit,
    openSucsessAlert,
    element,
    setsubmitErrs,
  } = useContext(ApiContext);
  const parames = useParams("id");
  const handleOnchangeImage = (imageIndex, imageUrl) => {
    setformData({
      ...formData,
      images: formData.images.map((elem, index) =>
        imageIndex == index ? imageUrl[0] : elem
      ),
    });
    // setformData({ ...formData, images: [...imageUrl] });
  };
  const [categories, setcategories] = useState({
    data: [],
    loader: { status: "loading", text: "جاري تحميل الأقسام" },
  });
  const getAllCategories = () => {
    setcategories({
      ...categories,
      loader: { status: "loading", text: "جاري تحميل الأقسام" },
    });
    myFeach(`dashboard/category?limit=1000`, "GET")
      .then((data) => {
        if (data.categories?.length === 0) {
          setcategories({
            data: data.categories,
            loader: { status: "failed", text: `لا يوجد الأقسام` },
          });
        } else {
          setcategories({
            data: data.categories,
            loader: { status: "done", text: "" },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setcategories({
          ...categories,
          loader: { status: "failed", text: `فشل تحميل الأقسام` },
        });
      });
  };

  useEffect(() => {
    getAllCategories();
    if (!window.location.href.includes("edit")) {
      setformData({
        name: "",
        description: "",
        width: "",
        height: "",
        length: "",
        maxload: "",
        material: "",
        images: [""],
        price: "",
        addDiscount: false,
        priceAfterDiscount: "",
        shippingFees: "",
        categories: [""],
        colors: [[""]],
        shippingMethod: "",
      });
    }
  }, []);
  useEffect(() => {
    return setsubmitErrs({});
  }, []);
  useEffect(() => {
    console.log(formData);
  }, [formData]);
  return (
    <div className="page_container">
      {openSucsessAlert && <SucsessAlert msg={`عملية ناجحة`} />}
      <PageTitle title="إضافة منتج جديد" />
        <form
          onSubmit={(e) =>
            hamdleSubmit(
              e,
              "dashboard/product",
              {
                name: "",
                description: "",
                width: "",
                height: "",
                length: "",
                maxload: "",
                material: "",
                images: [""],
                price: "",
                addDiscount: false,
                priceAfterDiscount: "",
                shippingFees: "",
                categories: [""],
                colors: [[""]],
                shippingMethod: "",
              },
              "product",
              ["name", "price", "shippingFees", "shippingMethod"],
              false
            )
          }
          className="add_form"
        >
          <div className="form_row flex-wrap">
            <CustomInput
              lable="اسم المنتج"
              name="name"
              inputErr={submitErrs?.name}
              value={formData?.name}
              type="text"
              handleOnChange={handleOnChangeInput}
            />
            <CustomInput
              lable="وصف المنتج"
              name="description"
              value={formData?.description}
              inputErr={submitErrs?.description}
              type="textArea"
              handleOnChange={handleOnChangeInput}
            />
            <MultiAbleSelect
              formData={formData}
              setformData={setformData}
              options={categories}
              value={formData?.categories ? formData.categories : []}
            />
          </div>
          <div className="form_row flex-wrap">
            <CustomInput
              lable="العرض"
              name="width"
              value={formData?.width}
              inputErr={submitErrs?.width}
              type="number"
              handleOnChange={handleOnChangeInput}
            />
            <CustomInput
              lable="الارتفاع"
              name="height"
              value={formData?.height}
              inputErr={submitErrs?.height}
              type="number"
              handleOnChange={handleOnChangeInput}
            />
            <CustomInput
              lable="العمق"
              name="length"
              value={formData?.length}
              inputErr={submitErrs?.length}
              type="number"
              handleOnChange={handleOnChangeInput}
            />
            <CustomInput
              lable="قوة التحميل بالكيلو جرام"
              name="maxload"
              value={formData?.maxload}
              inputErr={submitErrs?.maxload}
              type="number"
              handleOnChange={handleOnChangeInput}
            />
            <CustomInput
              lable="المادة المصنوع منها"
              name="material"
              value={formData?.material}
              inputErr={submitErrs?.material}
              type="select"
              options={materials}
              handleOnChange={handleOnChangeInput}
            />
            <CustomInput
              lable="طريقة الشحن"
              name="shippingMethod"
              value={formData?.shippingMethod}
              inputErr={submitErrs?.shippingMethod}
              type="select"
              options={shipingMethods}
              handleOnChange={handleOnChangeInput}
            />
          </div>
          <div className="form_row flex-wrap">
            <CustomInput
              lable="السعر الأصلي بالريال"
              name="price"
              value={formData?.price}
              inputErr={submitErrs?.price}
              type="number"
              handleOnChange={handleOnChangeInput}
            />
            <div className="flex flex-col">
              <CustomInput
                lable="هل ترغب في إضافة خصم"
                name=""
                value={formData?.addDiscount}
                inputErr={submitErrs?.addDiscount}
                type="checkBox"
                handleOnChange={(value) => {
                  setformData({
                    ...formData,
                    addDiscount: value,
                    priceAfterDiscount:
                      value == true ? formData.priceAfterDiscount : "",
                  });
                }}
              />
              <div className="-mt-7 ">
                <CustomInput
                  placeHolder="   أدخل السعر بعد الخصم بالريال"
                  name="priceAfterDiscount"
                  value={formData?.priceAfterDiscount}
                  inputErr={submitErrs?.priceAfterDiscount}
                  type="number"
                  handleOnChange={handleOnChangeInput}
                  disabled={!formData?.addDiscount}
                />
              </div>
            </div>
            <CustomInput
              lable=" مصاريف الشحن  بالريال"
              name="shippingFees"
              value={formData?.shippingFees}
              inputErr={submitErrs?.shippingFees}
              type="number"
              handleOnChange={handleOnChangeInput}
            />
          </div>
          <div className="form_row flex-wrap">
            <div className="flex justify-start items-center flex-wrap ">
              <CustomInputImge
                imageAppendKey="images"
                lable="إرفاق صورة"
                handleOnChange={(imageUrl) => handleOnchangeImage(0, imageUrl)}
                apiPath="dashboard/product/upload-images"
                imgeUrl={formData.images?.length && formData?.images[0]}
              />
              {formData?.images?.map((elem, index) => (
                <div key={index} className="m-2 relative">
                  {index > 0 && (
                    <>
                      <div
                        className="pointer absolute top-0 left-1 bg-red-400 text-white my_rounded  w-6 h-6 text-lg text-center"
                        onClick={() =>
                          setformData({
                            ...formData,
                            images: formData?.images.filter(
                              (item, filterIndex) => filterIndex != index
                            ),
                          })
                        }
                      >
                        X
                      </div>
                      <CustomInputImge
                        imageAppendKey="images"
                        lable="إرفاق صورة"
                        apiPath="dashboard/product/upload-images"
                        handleOnChange={(imageUrl) =>
                          handleOnchangeImage(index, imageUrl)
                        }
                        imgeUrl={
                          formData.images?.length && formData.images[index]
                        }
                      />
                    </>
                  )}
                </div>
              ))}
              <div
                className="bg_secondary text-white h-fit p-2 pointer mt-auto mr-10"
                onClick={() =>
                  setformData({
                    ...formData,
                    images: [...formData?.images, ""],
                  })
                }
              >
                إضافة صورة أخرى +
              </div>
            </div>
            <h5 className="text-xs text-red-400 w-full text-start">
              {submitErrs?.images}
            </h5>
          </div>
          <SelectColor
            key={"categories"}
            itemColors={formData?.colors}
            errour={submitErrs?.colors}
            setitemColors={(data) => setformData({ ...formData, colors: data })}
          />
          <button className="submit_product">
            {submitLoader.status === "loading" ? (
              <div className="loader"></div>
            ) : (
              <>إضافة</>
            )}
          </button>
          <h5 className="text-red-400 text-xs">{submitErrs?.error}</h5>
        </form>
    </div>
  );
}
const shipingMethods = [
  { title: "", value: "" },
  { value: "جوي", title: "جوي" },
  { value: "بري", title: "بري" },
  { value: "بحري", title: "بحري" },
];
const materials = [
  { title: "", value: "" },
  { value: "خشب", title: "خشب" },
  { value: "زجاج", title: "زجاج" },
  { value: "جلد", title: "جلد" },
  { value: "قماش", title: "قماش" },
];
