import React from "react";

export default function CustomInput({
  lable,
  name,
  value,
  handleOnChange,
  type = "text",
  required,
  options,
  lang = false,
  inputErr = "",
  placeHolder = "",
  disabled = false,
  loader = { status: "done" },
}) {
  return (
    <div className="flex flex-col mt-3">
      {type !== "checkBox" && <label className="mb-2">{lable}</label>}
      {type === "textArea" && (
        <textarea
          required={required}
          name={name}
          value={value}
          onChange={handleOnChange}
          className="my_input "
        />
      )}

      {type === "select" && (
        <>
          {loader.status === "done" ? (
            <select name={name} onChange={handleOnChange} value={value}>
              {options.map((elem, index) => (
                <option key={index} value={elem.value ? elem.value : elem._id}>
                  {elem.title ? elem.title : elem.name}
                </option>
              ))}
            </select>
          ) : (
            <div className="input">
              {loader.status === "failed" ? (
                <h5 className="text-red-400 text-xs">{loader.text}</h5>
              ) : (
                <div className="loader"></div>
              )}
            </div>
          )}
        </>
      )}
      {type === "checkBox" && (
        <div className="flex flex-row ">
          <input
            value={value}
            checked={value}
            type="checkbox"
            onChange={(e) => {
              handleOnChange(e.target.checked);
            }}
            name={name}
            className=" radio z_index_5"
          />
          <label className="">{lable}</label>
        </div>
      )}
      {(type === "number" || type === "text") && (
        <input
          required={required}
          type={type}
          name={name}
          value={value}
          onChange={handleOnChange}
          placeholder={placeHolder}
          className={`my_input ${lang == "en" ? "text_english" : ""} `}
          disabled={disabled}
        />
      )}
      <h6 className="err_messsage">{inputErr}</h6>
    </div>
  );
}
