import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../componentes/PageTitle";
import CustomInput from "../componentes/CustomInput";
import CustomInputImge from "../componentes/CustomInputImge";
import CustomTable from "../componentes/CustomTable";
import CustomPagination from "../componentes/CustomPagination";
import CustomSearch from "../componentes/CustomSearch";
import { ApiContext } from "../ApiContext";
import SucsessAlert from "../componentes/SucsessAlert";
import CustomLoader from "../componentes/CustomLoader";
import { myFeach } from "../globalFunctions/Apis";
export default function AddNotefication() {
  const [users, setusers] = useState({data:[],loader:{status:"",text:""},lastPage:""});
  const [page, setpage] = useState(1);
  const {
    formData,
    submitErrs,
    submitLoader,
    handleOnChangeInput,
    hamdleSubmit,
    openSucsessAlert,
  } = useContext(ApiContext);

  const getAllUsers = (key) => {
    // fech api get all users
    //set all user and set page and set last page
  };
  const feachUsers = () => {
    setusers({
      ...users,
      loader: { status: "loading", text: "جاري تحميل المستخدمين" },
    });
    myFeach(
      `dashboard/users?page=${page}&limit=6 `,
      "GET"
    )
      .then((data) => {
        if (data["users"]?.length === 0) {
          setusers({
            data: data["users"],
            loader: { status: "failed", text: `مستخدمين` },
            lastPage: data.totalPages,
          });
        } else {
          setusers({
            data: data["users"],
            loader: { status: "done", text: "" },
            lastPage: data.totalPages,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setusers({
          ...users,
          loader: { status: "failed", text: `فشل تحميل العملاء` },
        });
      });
  };
  const handleCheckOneUser = (e) => {
    // if (e.target.checked) {
    //   setformData({
    //     ...formData,
    //     targetusersIds: [...formData.targetusersIds, e.target.value],
    //   });
    // } else {
    //   setformData({
    //     ...formData,
    //     targetusersIds: formData.targetusersIds.filter(
    //       (elem) => elem != e.target.value
    //     ),
    //   });
    // }
  };
  useEffect(() => {
    handleOnChangeInput({ target: { value: "dashboard", name: "type" } });
  }, []);
  useEffect(() => {
    if (formData.targetCustomers == "اختيار بعض العملاء") {
    }
  }, [formData]);
  useEffect(() => {
    feachUsers();
  }, [page]);

  return (
    <div className="page_container">
      <PageTitle title="إضافة إشعار جديد" />
      {openSucsessAlert && <SucsessAlert msg={"تم إضافة الإشعار بنجاح"} />}
      <form
        onSubmit={(e) =>
          hamdleSubmit(
            e,
            "dashboard/notification",
            {
              title: "",
              description: "",
              type: "",
              targetCustomers: "",
              receivers: [],
            },
            "ad",
            ["title", "description", "targetCustomers"]
          )
        }
        className="add_form"
      >
        <div className="form_row">
          <CustomInput
            lable="العنوان"
            name="title"
            value={formData?.title}
            inputErr={submitErrs?.title}
            type="text"
            handleOnChange={handleOnChangeInput}
          />
          <CustomInput
            lable="الوصف"
            name="description"
            value={formData?.description}
            inputErr={submitErrs?.description}
            type="textArea"
            handleOnChange={handleOnChangeInput}
          />
        </div>
        <div className="form_row">
          <CustomInput
            lable="العملاء المستهدفين"
            name="targetCustomers"
            inputErr={submitErrs?.targetCustomers}
            value={formData?.targetCustomers}
            type="select"
            handleOnChange={handleOnChangeInput}
            options={[
              { title: "", value: "" },
              { title: "جميع العملاء", value: "جميع العملاء" },
              {
                title: "العملاء أصحاب السلة الغير مكتملة",
                value: "العملاء أصحاب السلة الغير مكتملة",
              },
              { title: "الزوار", value: "الزوار" },
              { title: "اختيار بعض العملاء", value: "اختيار بعض العملاء" },
            ]}
          />
          <CustomInputImge
            apiPath="dashboard/notification/image"
            imgeUrl={formData.image}
            handleOnChange={(img) =>
              handleOnChangeInput({ target: { value: img, name: "image" } })
            }
            inputErr={submitErrs?.image}
            lable="إضافة صورة"
          />
        </div>
        {formData.targetCustomers === "اختيار بعض العملاء" && (
          <div className="">
            <h2 className="mt-5 mb-5 text-2xl">اختيار عملاء لإرسال الإشعار</h2>
            <div className="select_user_to_notefication">
              <>
                <CustomSearch feachList={feachUsers} btns={filterBtns} />
                <div style={{ height: "400px" }} className="mt-11">
                  {users.loader?.status === "done" ? (
                    <>
                      <CustomTable headers={tableHeader}>
                        {users.data?.map((elem, index) => (
                          <tr
                            key={index}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              <input
                                type="checkbox"
                                value={elem._id}
                                onChange={handleCheckOneUser}
                                checked={formData.targetusersIds.includes(
                                  elem._id
                                )}
                              />
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {index + 1}
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:text-sky-500"
                            >
                              <Link to="/user-detailes">{elem.name}</Link>
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {elem.location}
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {elem.numberOfOrders}
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {elem.numberOfcartProducts == 0 ? (
                                "السلة فارغة"
                              ) : (
                                <>
                                  {elem.numberOfcartProducts}{" "}
                                  <span className="text-xs text-gray-500">
                                    منتجات
                                  </span>
                                </>
                              )}
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {elem.isBloucked == true
                                ? "عمبل محظور"
                                : "عميل غير محظور"}
                            </th>
                          </tr>
                        ))}
                      </CustomTable>
                      <CustomPagination
                        numperOfPages={users.lastPage}
                        loader={users?.loader}
                      />
                    </>
                  ) : (
                    <CustomLoader loader={users.loader} />
                  )}
                </div>
              </>
            </div>
          </div>
        )}
        <button className="h-11" disabled={submitLoader.status === "loading"}>
          {submitLoader.status === "loading" ? (
            <div className="loader"></div>
          ) : (
            "إضافة"
          )}
        </button>
        <h4 className="err_messsage">{submitErrs?.error}</h4>
      </form>
    </div>
  );
}

const tableHeader = [
  "#",
  "م",
  "الاسم",
  "العنوان",
  "عدد الطلبات السابقة",
  "عدد المنتجات في السلة",
  "الحالة/(Bloucked)",
];
const filterBtns = [
  { text: "الجميع", key: "all", color: "#0EA5E9" },
  { text: "أصحاب السلة المفتوحة", key: "opened", color: "#FB923C" },
  { text: "العملاء المحظورون", key: "bloucked", color: "#EF4444" },
  { text: "العملاء الغير محظورين", key: "vrified", color: "#22C55E" },
];
