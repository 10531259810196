import React from "react";
import { MdOutlineSmsFailed } from "react-icons/md";
export default function CustomLoader({ loader }) {
  return (
    <div className="w-full flex flex-col items-center pt-11">
      {loader.status === "loading" && <div className="Large_Loader"></div>}
      {loader.status === "failed" && (
        <MdOutlineSmsFailed color="red" size={40} />
      )}
      <h4 className="mt-4">{loader.text}</h4>
    </div>
  );
}
