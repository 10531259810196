import Cookies from "js-cookie";
import React from "react";
import { Navigate } from "react-router-dom";
export default function ProtectedRoute({ children }) {
  if (Cookies.get("token")) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
}
